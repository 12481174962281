import React, { Component } from 'react'
import Portfolio from "./components/layout2/Portfolio"
import JsonData from './data/data.json';
import OguzSancaktar from './components/oguzSancaktar';
import NavigationAnasayfa from './components/EnnavigationAnasayfa';

export class EnProducts extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }
  

  componentDidMount() {
    this.getlandingPageData();
    window.scrollTo(0, 0)
  }

  render() {
    return (
      <div>
        <NavigationAnasayfa />
        <Portfolio />

        <OguzSancaktar data={this.state.landingPageData.EnContact} />
      </div>
    )
  }
}

export default EnProducts;
