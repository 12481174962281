import React, { Component } from "react";
import history from "../History"
import './EnHeader.css'

export class EnHeader extends Component {
  render() {
    return (
      <header id="header">
        <div className="intro">
          <div className="overlay">
            <div className="container">
              <div className="row">
                <div  className="col-md-12 intro-text header-text">
                  
                  <h1>
                    {this.props.data ? this.props.data.title : "Loading"}
                    <span></span>
                  </h1>
                  <br/><br/>
                  <button
                    className="btn btn-custom btn-lg page-scroll"
                    onClick={() => history.push("EnglishContact")}
                  >
Detailed Communication                  </button>{" "} <br/> <br/><br/><br/>
<p>
                    {this.props.data ? this.props.data.paragraph : "Loading"}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}

export default EnHeader;
