import React, { Component } from 'react'
import EnNavigation from './components/Ennavigation';
import EnHeader from './components/Enheader';
import EnFeatures from './components/Enfeatures';
// import EnHeader from './components/EnHeader';
import EnAbout from './components/EnAbout'
// import Services from './components/services';
import EnKurumsal from './components/Enkurumsal';
import EnGallery from './components/Engallery';
import EnTestimonials from './components/Entestimonials';
import EnTeam from './components/EnTeam';
import EnContact from './components/Encontact';
import JsonData from './data/data.json';


export class English extends Component {
  state = {
    landingPageData: {},
  }
  getlandingPageData() {
    this.setState({landingPageData : JsonData})
  }

  componentDidMount() {
    this.getlandingPageData();
  }

  render() {
    return (
   
     
      <div> 
      
    
        <EnNavigation />
        <EnHeader data={this.state.landingPageData.EnHeader} />
        {/* <EnHeader data={this.state.landingPageData.EnHeader} /> */}
        <EnAbout data={this.state.landingPageData.EnAbout} />


        <EnFeatures data={this.state.landingPageData.EnFeatures} />
        {/* <Services data={this.state.landingPageData.Services} /> */}
        <EnKurumsal data={this.state.landingPageData.EnKurumsal} />
        <EnGallery />
        <EnTestimonials data={this.state.landingPageData.EnTestimonials} />
        <EnTeam data={this.state.landingPageData.EnTeam} />
        <EnContact data={this.state.landingPageData.EnContact} />
      </div>
    )
  }
}

export default English;
