import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import App from './App';
// import Iletisim from "./Iletisim"
import * as serviceWorker from './serviceWorker';
// import Products from './Products';
import { BrowserRouter as Router } from 'react-router-dom';
import Routes from "./Routes"

ReactDOM.render(

  <React.StrictMode>

    <Router>
   
   <Routes/>
    </Router>
    

  </React.StrictMode>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
