import React, { useState, useMemo } from "react";
import { Container, Row, Col } from "reactstrap";
import PortfolioItem from "./PortfolioItem";
import './layout.css'

const portfoliolist = [

  // PURE PLANT OILS PURE PLANT OILS   PURE PLANT OILS  PURE PLANT OILS
  // PURE PLANT OILS
  // PURE PLANT OILS
  // PURE PLANT OILS


  {
    id: 1,
    name: "Coconut Oil Cold Press 50ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/1.jpg",
  },
  {
    id:2,
    name: "Black Seed Oil 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/2.jpg",
  },
  {
    id:3,
    name: "St. John’ s Wort Oil 50ml",
  
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/3.jpg",
  },
  {
    id:4,
    name: "Sweet Almond Oil Cold Press 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/4.jpg",
  },
  {
    id:5,
    name: "Sesame Seed Oil Cold Press 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/5.jpg",
  },
  {
    id:6,
    name: "Hot Peppers Oil Cold Press 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/6.jpg",
  },
  {
    id:7,
    name: "Pine Turpentine Oil Distilled 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/7.jpg",
  },
  {
    id:8,
    name: "Grape Seed Oil Cold Press 50ml	",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/8.jpg",
  },
  {
    id:9,
    name: "Rosehip Oil Cold Press 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/9.jpg",
  },
  {
    id:10,
    name: "Rosary Tree Oil 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/10.jpg",
  },
  {
    id:11,
    name: "Garlic Oil Cold Press 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/11.jpg",
  },
  
  {
    id: 12,
    name: "Safflower Oil Cold Press 250ml",
  
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/12.jpg",
  },
  {
    id: 13,
    name: "Horse Chestnut Oil 250ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/13.jpg",
  },
  {
    id: 14,
    name: "Carrot Oil Cold Press 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/14.jpg",
  },
  {
    id: 15,
    name: "Jojoba Oil 20ml	Jojoba Yağı Soğuk Pres 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/15.jpg",
  },
  {
    id: 16,
    name: "Pure Olive Oil Cold Press 50ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/16.jpg",
  },
  {
    id: 17,
    name: "Indian Oil Cold Press 50ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/17.jpg",
  },
  {
    id: 18,
    name: "Ylang Ylang Oil Cold Distilled 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/18.jpg",
  },
  {
    id: 19,
    name: "Tincture Oil Cold Press 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/19.jpg",
  },
  {
    id: 20,
    name: "	Violet Oil 20ml	Menekşe Yağı 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/20.jpg",
  },
  {
    id: 21,
    name: "Lavander Oil Distilled 10ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/21.jpg",
  },
  {
    id:22,
    name: "Clove Oil Distilled 10ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/22.jpg",
  },
  {
    id: 23,
    name: "St. John’ s Wort Oil 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/23.jpg",
  },
  {
    id: 24,
    name: "Borage Oil Cold Press 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/24.jpg",
  },
  {
    id: 25,
    name: "Tea Tree Oil Distilled 10ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/25.jpg",
  },
  {
    id: 26,
    name: "Rosemary Oil Distilled 10ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/26.jpg",
  },
  {
    id: 27,
    name: "Grape Seed Oil Cold Press 250ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/27.jpg",
  },
  {
    id: 28,
    name: "Sweet Almond Oil Cold Press 250ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/28.jpg",
  },
  {
    id: 29,
    name: "Sesame Seed Oil Cold Press 250ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/29.jpg",
  },
  {
    id: 30,
    name: "Pure OliveOil Cold Press 250ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/30.jpg",
  },
  {
    id: 31,
    name: "Linseed Oil Cold Press 250ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/31.jpg",
  },
  {
    id: 32,
    name: "St. John’ s Wort Oil 250ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/32.jpg",
  },
  {
    id: 33,
    name: "Black Seed Oil 250ml",
  
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/33.jpg",
  },
  {
    id: 34,
    name: "Pine Turpentine Oil Distilled 250ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/34.jpg",
  },
  {
    id: 35,
    name: "Trout Oil 250ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/35.jpg",
  },
  {
    id: 36,
    name: "Sesame Seed Oil Cold Press 50ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/36.jpg",
  },
  {
    id: 37,
    name: "Cabbage Oil 50ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/37.jpg",
  },
  {
    id: 38,
    name: "Linseed Oil Cold Press 50ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/38.jpg",
  },
  {
    id: 39,
    name: "Cocoa Oil Solid 50ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/39.jpg",
  },
  {
    id: 40,
    name: "Poppy Seed Oil Cold Press 50ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/40.jpg",
  },
  {
    id: 41,
    name: "Mustard Seed Oil Cold Press 50ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/41.jpg",
  },
  {
    id: 42,
    name: "Hazelnut Oil Cold Press 50ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/42.jpg",
  },
  {
    id: 43,
    name: "Black Seed Oil 50ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/43.jpg",
  },
  {
    id: 44,
    name: "Pine Turpentine Oil Distilled 50ml	",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/44.jpg",
  },
  {
    id: 45,
    name: "Walnut Oil Cold Press 50ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/45.jpg",
  },
  {
    id: 46,
    name: "Trout Oil 50ml",
   
    category: "PURE PLANT OILS",
    url: "img/portfolio/46.jpg",
  },
  {
    id: 47,
    name: "Sea Buckthorn Oil 20ml",
  
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/47.jpg",
  },
  {
    id: 48,
    name: "Cress Seed Oil Cold Press 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/48.jpg",
  },
  {
    id: 49,
    name: "Peach Kernel Oil Cold Press 20ml	",
  
    category: "PURE PLANT OILS",
    url: "img/portfolio/49.jpg",
  },
  {
    id: 50,
    name: "Shea Oil Cold Press 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/50.jpg",
  },
  {
    id: 51,
    name: "Cedarwood Oil Distilled 20ml	",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/51.jpg",
  },
  {
    id: 52,
    name: "Fennel Oil Cold Press 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/52.jpg",
  },
  {
    id: 53,
    name: "Orange Oil Distilled 20ml	",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/53.jpg",
  },
  {
    id: 54,
    name: "Rice Bran Oil 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/54.jpg",
  },
  {
    id: 55,
    name: "Rosewood Oil 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/55.jpg",
  },
  {
    id: 56,
    name: "Chamomile Oil Distilled 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/56.jpg",
  },
  {
    id: 57,
    name: "Eucalyptus Oil Distilled 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/57.jpg",
  },
  {
    id: 58,
    name: "Eucalyptus Oil Distilled 10ml",
  
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/58.jpg",
  },
  {
    id: 59,
    name: "Peppermint Oil Distilled 20ml",
  
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/59.jpg",
  },
  {
    id: 60,
    name: "Peppermint Oil Distilled 10ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/60.jpg",
  },
  {
    id: 61,
    name: "Parsely Seed Oil Cold Press 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/61.jpg",
  },
  {
    id: 62,
    name: "Macadamia Oil Cold Press 20ml",
  
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/62.jpg",
  },
  {
    id: 63,
    name: "Lemon Oil Distilled 20ml	",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/63.jpg",
  },
  {
    id: 64,
    name: "Lavender Oil Distilled 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/64.jpg",
  },
  {
    id: 65,
    name: "Wintergreen Oil Distilled 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/65.jpg",
  },
  {
    id: 66,
    name: "Thyme Oil Distilled 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/66.jpg",
  },
  {
    id: 67,
    name: "Thyme Oil Distilled 10ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/67.jpg",
  },
  {
    id: 68,
    name: "Clove Oil Distilled 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/68.jpg",
  },
  {
    id: 69,
    name: "Black Pepper Oil Cold Press 20ml	",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/69.jpg",
  },
  {
    id: 70,
    name: "Pumpkin Seed Oil Cold Press 20ml	",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/70.jpg",
  },
  {
    id: 71,
    name: "Nettle Seed Oil Cold Press 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/71.jpg",
  },
  {
    id: 72,
    name: "Rose Oil 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/72.jpg",
  },
  {
    id: 73,
    name: "Grapefruit Oil Distilled 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/73.jpg",
  },
  {
    id: 74,
    name: "Ostrich Oil 20ml",
    
    category: "PURE PLANT OILS",
    url: "img/portfolio/74.jpg",
  },
  {
    id: 75,
    name: "Thistle Seed Oil Cold Press 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/75.jpg",
  },
  {
    id: 76,
    name: " Tea Tree Oil Distilled 20ml  ",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/76.jpg",
  },
  {
    id: 77,
    name: " Wheat Germ Oil 20ml  ",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/77.jpg",
  },
  {
    id: 78,
    name: "Rosemary Oil Distilled 20ml ",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/78.jpg",
  },
  {
    id: 79,
    name: " Bergamot Oil Distilled 20ml  ",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/79.jpg",
  },
  {
    id: 80,
    name: "Calendula Oil 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/80.jpg",
  },
  {
    id: 81,
    name: "Avocado Oil 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/81.jpg",
  },
  {
    id: 82,
    name: "Horse Chestnut Oil 20ml	",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/82.jpg",
  },
  {
    id: 83,
    name: "Argan Oil Cold Press 20ml",
  
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/83.jpg",
  },
  {
    id: 84,
    name: "Juniper Oil Distilled 20ml",
  
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/84.jpg",
  },
  {
    id: 85,
    name: "Anise Oil Cold Press 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/85.jpg",
  },
  {
    id: 86,
    name: "Aloe Vera Oil 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/86.jpg",
  },
  {
    id: 87,
    name: "Sage Oil Distilled 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/87.jpg",
  },
  {
    id: 88,
    name: "Sage Oil Distilled 10ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/88.jpg",
  },
  {
    id: 89,
    name: "Almond Oil Cold Press 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/89.jpg",
  },
  {
    id: 90,
    name: "Kabak Çekirdeği Yağı Soğuk Pres 50ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/90.jpg",
  },
  {
    id: 91,
    name: "Pumpkin Seed Oil Cold Press 50ml	",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/91.jpg",
  },
  {
    id: 92,
    name: "Cinnamon Oil Distilled 20ml",
  
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/92.jpg",
  },
  {
    id: 93,
    name: "	Blue Anemone Oil Distilled 20ml",
    
  
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/93.jpg",
  },
  {
    id: 94,
    name: "Apricot Seed Oil Cold Press 20ml",
    
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/94.jpg",
  },
  {
    id:95,
    name: "Pomegranate Flower Oil Cold Press 20ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/95.jpg",
  },
  {
    id:96,
    name: "  Glycerine 50ml	Gliserin 50ml",
   
    number: "1",
    category: "PURE PLANT OILS",
    url: "img/portfolio/96.jpg",
  },
 
  


// ÖZELL KARIŞIM YAĞLAR----------ÖZELL KARIŞIM YAĞLAR--------------ÖZELL KARIŞIM YAĞLAR-------------ÖZELL KARIŞIM YAĞLAR


  {
    id: 97,
    name: "Eyebrow and Eyelash Care Oil 20ml",
   
    number: "2",
    category: "SPECIAL PLANT OILS",
    url: "img/portfolio/97.jpg",
  },

  {
    id: 98,
    name: "Beard and Moustache Care Oil 50ml				",
    
    number: "2",
    category: "SPECIAL PLANT OILS",
    url: "img/portfolio/98.jpg",
  },

  {
    id: 99,
    name: "Aromatherapy Massage Oil Exotic 150ml",
    
    number: "2",
    category: "SPECIAL PLANT OILS",
    url: "img/portfolio/99.jpg",
  },

  {
    id: 100,
    name: "Snoil Natural Plant Oil 20ml",
    
    
    number: "2",
    category: "SPECIAL PLANT OILS",
    url: "img/portfolio/100.jpg",
  },

  {
    id: 101,
    name: "After Epilation Care Oil 150ml ",
    
    number: "2",
    category: "SPECIAL PLANT OILS",
    url: "img/portfolio/101.jpg",
  },
  {
    id: 102,
    name: "Natural Body Care Oil 150ml",
   
    number: "2",
    category: "SPECIAL PLANT OILS",
    url: "img/portfolio/102.jpg",
  },
  {
    id: 103,
    name: "Natural Massage Oil 150ml",
    
    number: "2",
    category: "SPECIAL PLANT OILS",
    url: "img/portfolio/103.jpg",
  },
  {
    id: 104,
    name: "Aromatherapy Massage Oil Relax 150ml	",
   
    number: "2",
    category: "SPECIAL PLANT OILS",
    url: "img/portfolio/104.jpg",
  },



      // Cilt Bakımı        Cilt Bakımı         Cilt Bakımı         Cilt Bakımı         Cilt Bakımı






  {
    id: 105,
    name: "Liquid Peeling Gel 150ml (red algae extract)	",
   
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/105.jpg",
  },
  {
    id: 106,
    name: "Collagen Cream 50ml (red algae extract)		",
   
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/106.jpg",
  },
  {
    id: 107,
    name: "Almond Milk 75ml	",
    
    
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/107.jpg",
  },
  {
    id: 108,
    name: "Foot Care Spray 50ml				",
    
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/108.jpg",
  },
  {
    id: 109,
    name: "Skin Mask Snail Secretion Essence 140g		",
    
  
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/109.jpg",
  },

  {
    id: 110,
    name: "Cream With Apricot Extract 45g		",
    
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/110.jpg",
  },
  {
    id: 111,
    name: "24 Disposable Skin Mask (For All Skin)	",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/111.jpg",
  },
  {
    id: 112,
    name: "Horse Chestnut Gel 500ml	",
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/112.jpg",
  },
  {
    id: 113,
    name: "Estomin Arnica Gel 100ml",
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/113.jpg",
  },
  {
    id: 114,
    name: "Estomin-C Calendula Cream 50ml	",
   
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/114.jpg",
  },

  {
    id: 115,
    name: "Horse Chestnut Cream 50ml	",
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/115.jpg",
  },
  {
    id: 116,
    name: "Horse Chestnut Gel Spray 275ml		",
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/116.jpg",
  },
  {
    id: 117,
    name: "	Natural Skin Mask 140g		",
    
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/117.jpg",
  },
  {
    id: 118,
    name: "Partridge Raisin Cream Set (Two in One) 185g		",
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/118.jpg",
  },
  {
    id: 119,
    name: "Horse Chestnut Extract Cream 45g	",
   
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/119.jpg",
  },

  {
    id: 120,
    name: "Calendula Extract Cream 30g",
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/120.jpg",
  },
  {
    id: 121,
    name: "Centaury Extract Cream 45g	",
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/121.jpg",
  },
  {
    id: 122,
    name: "Snail Secretion Cream 45g	",
    
    
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/122.jpg",
  },
  {
    id: 123,
    name: "Rose Water 275ml (100% Natural)			",
   
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/123.jpg",
  },
  {
    id: 124,
    name: "Horse Chestnut Gel 275ml	",
    
    
    number: "3",
    category: "SKIN CARE ",
    url: "img/portfolio/124.jpg",
  },
  {
    id: 125,
    name: "Natural Hair Care Oil 150ml		",
    
    number: "3",
    category: "HAIR CARE",
    url: "img/portfolio/125.jpg",
  },
  {
    id: 126,
    name: "Keratin Hair Care Mask 150gr				",
   
    
    number: "3",
    category: "HAIR CARE",
    url: "img/portfolio/126.jpg",
  },
 
  {
    id: 127,
    name: "Shea Butter Shampoo 330ml		",
    
    
    number: "3",
    category: "HAIR CARE",
    url: "img/portfolio/127.jpg",
  },
  {
    id: 128,
    name: "Argan Shampoo 330ml				",
    
    number: "3",
    category: "HAIR CARE",
    url: "img/portfolio/128.jpg",
  },
  {
    id: 129,
    name: "Activated Carbon Shampoo 330ml			",
    
    number: "3",
    category: "HAIR CARE",
    url: "img/portfolio/129.jpg",
  },
 
  {
    id: 130,
    name: "Biotin Shampoo (Anti-Loss) 330ml			",
    
    
    number: "3",
    category: "HAIR CARE",
    url: "img/portfolio/130.jpg",
  },




        // NATURAL SOAPS------------- NATURAL SOAPS------------- NATURAL SOAPS------------- NATURAL SOAPS------------- NATURAL SOAPS-------------
   // NATURAL SOAPS------------- NATURAL SOAPS------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR-------------
      // DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR-------------
         // DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR-------------
            // DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR-------------
  {
    id: 131,
    name: "Tea Tree Soap 150g					",
    
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/131.jpg",
  },
  {
    id: 132,
    name: "Red Algae Soap 150g						",
   
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/132.jpg",
  },
 
  {
    id: 133,
    name: "Collagen Soap 150g (Including Collagen)					",
   
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/133.jpg",
  },
  {
    id: 134,
    name: "Activated Carbon Soap 150g				",
    
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/134.jpg",
  },
 

  {
    id: 135,
    name: "Coconut Soap 150g					",
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/135.jpg",
  },
  {
    id: 136,
    name: "Citrus Garden Soap 150g					",
    
    
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/136.jpg",
  },
 
  {
    id: 137,
    name: "	Rice Bran Soap 150g					",
   
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/137.jpg",
  },
  {
    id: 138,
    name: "Avocado Soap 150g				",
    
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/138.jpg",
  },

  {
    id: 139,
    name: "Lavender Soap 150g				",
   
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/139.jpg",
  },
  {
    id: 140,
    name: "Vitamin E Soap 150g					",
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/140.jpg",
  },
 
  {
    id: 141,
    name: "Apricot Soap 150g			",
    
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/141.jpg",
  },
  {
    id: 142,
    name: "Juniper Tar Soap 150g			",
    
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/142.jpg",
  },
 

  {
    id: 143,
    name: "Pine Turpentine Soap 150g	",
   
    
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/143.jpg",
  },
  {
    id: 144,
    name: "Snail Soap 150g				",
    
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/144.jpg",
  },
 
  {
    id: 145,
    name: "Calendula Soap 150g						",
   
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/145.jpg",
  },
  {
    id: 146,
    name: "Isparta Rose Soap 150g				",
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/146.jpg",
  },
 
 
  {
    id: 147,
    name: "Exotic Soap 150g			",
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/147.jpg",
  },
  {
    id: 148,
    name: "Argan Soap 150g						",
    
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/148.jpg",
  },
 

  // {
  //   id: 149,
  //   name: "Kükürt Sabunu 150g					",
  //   description: "  Destek Kükürt Sabunu, kükürt içeren doğal bir sabundur. Kükürt, eski çağlardan bu yana bilinen ve doğada saf halde bulunabilen değerli bir elementtir. Sarı renklidir ve cilt bakımında kullanılabilir. Kükürt sabunu, akne ve sivilce oluşumunu engellemeye yardımcı olur. Cildin temizlenmesini destekler.    ",
  //   description2:"qua, Sulfur, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Sodium Chloride, Stearic Acid, Lauric Acid, Pentasodium Pentetate, Tetrasodium Etidronate.   ",
  //   description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
  //   number: "3",
  //   category: "NATURAL SOAPS",
  //   url: "img/portfolio/131.jpg",
  // },
  {
    id: 149,
    name: "	Shea Butter Soap 150g				",
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/131.jpg",
  },
 
  {
    id: 150,
    name: "	Aloe Vera Soap 150g						",
    
    
    number: "3",
    category: "NATURAL SOAPS",
    url: "img/portfolio/150.jpg",
  },
  


  
  {
    id: 151,
    name: "Garbage	Bags					",
  
    
    
    number: "3",
    category: "INDUSTRIAL PAPER PRODUCTS",
    url: "img/portfolio/151.jpg",
  },
 
  {
    id: 165,
    name: "Box Tissue						",
 
    
    
    number: "3",
    category: "INDUSTRIAL PAPER PRODUCTS",
    url: "img/portfolio/165.jpg",
  },
  {
    id: 190,
    name: " Folded Towel				",
 
    
    
    number: "3",
    category: "INDUSTRIAL PAPER PRODUCTS",

    url: "img/portfolio/191.jpg",
  },
  {
    id: 191,
    name: "Paper Towels						",
 
    
    
    number: "3",
    category: "INDUSTRIAL PAPER PRODUCTS",

    url: "img/portfolio/192.jpg",
  },
  {
    id: 192,
    name: "Paper Towels						",
 
    
    
    number: "3",
    category: "INDUSTRIAL PAPER PRODUCTS",

    url: "img/portfolio/193.jpg",
  },
    {
    id: 193,
    name: "Paper Towels						",
 
    
    
    number: "3",
    category: "INDUSTRIAL PAPER PRODUCTS",

    url: "img/portfolio/194.jpg",
  },
  
  {
    id: 194,
    name: "Dispenser 						",
 
    
    
    number: "3",
    category: "INDUSTRIAL PAPER PRODUCTS",

    url: "img/portfolio/202.jpg",
  },
  
  {
    id: 202,
    name: "Paper Towels			",
 
    
    
    number: "3",
    category: "INDUSTRIAL PAPER PRODUCTS",

    url: "img/portfolio/195.jpg",
  },
  


  
  {
    id: 157,
    name: "PVC Marble Panels			",
    description: "   122cm x 244cm x 3,5mm are standard dimensions. Sheet can be produced in different dimensions and thickness due to order quantity. (For example:  20DC FCL order  is about  2.640 m2.) PVC marble wall panels have been developed as an alternative in all areas where marble and ceramic are used in interior wall coverings. Our panels are ready to be assembled, clean, easy to apply, and designed and produced with a DIY system that our customers can install without the need for breaking and spilling. * High surface resistance against scratching and impact * Original marble hardness * Antibacterial, formaldehyde free, 100% asbestos free * B1 class flame retardant, does not emit toxic fumes * Waterproof and high moisture resistance * Quick and clean installation, wide selection of models * UV layer glossy surface Easy to clean and maintain * No radiation, environmentally friendly * Heat and sound insulation",
 
    
    
    number: "3",
    category: "PVC Marble Panels",
    url: "img/portfolio/157.jpg",
  },
  
  {
    id: 159,
    name: "PVC Marble Panels			",
    description: "   122cm x 244cm x 3,5mm are standard dimensions. Sheet can be produced in different dimensions and thickness due to order quantity. (For example:  20DC FCL order  is about  2.640 m2.) PVC marble wall panels have been developed as an alternative in all areas where marble and ceramic are used in interior wall coverings. Our panels are ready to be assembled, clean, easy to apply, and designed and produced with a DIY system that our customers can install without the need for breaking and spilling. * High surface resistance against scratching and impact * Original marble hardness * Antibacterial, formaldehyde free, 100% asbestos free * B1 class flame retardant, does not emit toxic fumes * Waterproof and high moisture resistance * Quick and clean installation, wide selection of models * UV layer glossy surface Easy to clean and maintain * No radiation, environmentally friendly * Heat and sound insulation",
 
    
    
    number: "3",
    category: "PVC Marble Panels",
    url: "img/portfolio/159.jpg",
  },{
    id: 160,
    name: "PVC Marble Panels			",
    description: "   122cm x 244cm x 3,5mm are standard dimensions. Sheet can be produced in different dimensions and thickness due to order quantity. (For example:  20DC FCL order  is about  2.640 m2.) PVC marble wall panels have been developed as an alternative in all areas where marble and ceramic are used in interior wall coverings. Our panels are ready to be assembled, clean, easy to apply, and designed and produced with a DIY system that our customers can install without the need for breaking and spilling. * High surface resistance against scratching and impact * Original marble hardness * Antibacterial, formaldehyde free, 100% asbestos free * B1 class flame retardant, does not emit toxic fumes * Waterproof and high moisture resistance * Quick and clean installation, wide selection of models * UV layer glossy surface Easy to clean and maintain * No radiation, environmentally friendly * Heat and sound insulation",
 
    
    
    number: "3",
    category: "PVC Marble Panels",
    url: "img/portfolio/160.jpg",
  },{
    id: 161,
    name: "PVC Marble Panels			",
    description: "   122cm x 244cm x 3,5mm are standard dimensions. Sheet can be produced in different dimensions and thickness due to order quantity. (For example:  20DC FCL order  is about  2.640 m2.) PVC marble wall panels have been developed as an alternative in all areas where marble and ceramic are used in interior wall coverings. Our panels are ready to be assembled, clean, easy to apply, and designed and produced with a DIY system that our customers can install without the need for breaking and spilling. * High surface resistance against scratching and impact * Original marble hardness * Antibacterial, formaldehyde free, 100% asbestos free * B1 class flame retardant, does not emit toxic fumes * Waterproof and high moisture resistance * Quick and clean installation, wide selection of models * UV layer glossy surface Easy to clean and maintain * No radiation, environmentally friendly * Heat and sound insulation",
 
    
    
    number: "3",
    category: "PVC Marble Panels",
    url: "img/portfolio/161.jpg",
  },
  {
    id: 163,
    name: "PVC Marble Panels			",
    description: "   122cm x 244cm x 3,5mm are standard dimensions. Sheet can be produced in different dimensions and thickness due to order quantity. (For example:  20DC FCL order  is about  2.640 m2.) PVC marble wall panels have been developed as an alternative in all areas where marble and ceramic are used in interior wall coverings. Our panels are ready to be assembled, clean, easy to apply, and designed and produced with a DIY system that our customers can install without the need for breaking and spilling. * High surface resistance against scratching and impact * Original marble hardness * Antibacterial, formaldehyde free, 100% asbestos free * B1 class flame retardant, does not emit toxic fumes * Waterproof and high moisture resistance * Quick and clean installation, wide selection of models * UV layer glossy surface Easy to clean and maintain * No radiation, environmentally friendly * Heat and sound insulation",
 
    
    
    number: "3",
    category: "PVC Marble Panels",
    url: "img/portfolio/163.jpg",
  },
  
  
  
  
  {
    id: 167,
    name: "Country & Classic Kitchen		",
 
    
    
    number: "3",
    category: "Kitchen Design",
    url: "img/portfolio/167.jpg",
  },
 
  {
    id: 166,
    name: "Country & Classic Kitchen			",
 
    
    
    number: "3",
    category: "Kitchen Design",
    url: "img/portfolio/166.jpg",
  },

  
  {
    id: 154,
    name: " Modern Kitchen  			",
 
    
    
    number: "3",
    category: "Kitchen Design",
    url: "img/portfolio/154.jpg",
  },
  {
    id: 155,
    name: "  Modern Kitchen   			",
 
    
    
    number: "3",
    category: "Kitchen Design",
    url: "img/portfolio/155.jpg",
  },
  {
    id: 153,
    name: "   Retro Kitchen       			",
 
    
    
    number: "3",
    category: "Kitchen Design",
    url: "img/portfolio/153.jpg",
  },
  {
    id: 152,
    name: "    Retro Kitchen     			",
 
    
    
    number: "3",
    category: "Kitchen Design",
    url: "img/portfolio/152.jpg",
  },

  {
    id: 168,
    name: "   Classic Doors         			",
 
    
    
    number: "3",
    category: "Door Design",
    url: "img/portfolio/168.jpg",
  },
  {
    id: 169,
    name: "      Classic Doors           			",
 
    
    
    number: "3",
    category: "Door Design",
    url: "img/portfolio/169.jpg",
  },
 
  {
    id: 170,
    name: "           Modern Doors                                                          			",
 
    
    
    number: "3",
    category: "Door Design",
    url: "img/portfolio/170.jpg",
  },
  {
    id: 171,
    name: "          Modern Doors                                                       			",
 
    
    
    number: "3",
    category: "Door Design",
    url: "img/portfolio/171.jpg",
  },
  {
    id: 172,
    name: "                  Sliding Doors                                                          			",
 
    
    
    number: "3",
    category: "Door Design",
    url: "img/portfolio/172.jpg",
  },
  {
    id: 173,
    name: "                  Sliding Doors                                                  			",
 
    
    
    number: "3",
    category: "Door Design",
    url: "img/portfolio/173.jpg",
  },
  {
    id: 174,
    name: "               Door Solutions                                                                                                           			",
 
    
    
    number: "3",
    category: "Door Design",
    url: "img/portfolio/174.jpg",
  },
  {
    id: 196,
    name: "                 Door Solutions                                                                                                            			",
 
    
    
    number: "3",
    category: "Door Design",
    url: "img/portfolio/196.jpg",
  },

  {
    id: 197,
    name: "           Door Solutions                                                                                                          			",
 
    
    
    number: "3",
    category: "Door Design",
    url: "img/portfolio/197.jpg",
  },
  {
    id: 198,
    name: "                Door Solutions                                                                                                          			",
 
    
    
    number: "3",
    category: "Door Design",
    url: "img/portfolio/198.jpg",
  },
  {
    id: 199,
    name: "                Door Solutions                                                                                                          			",
 
    
    
    number: "3",
    category: "Door Design",
    url: "img/portfolio/199.jpg",
  },
  {
    id: 200,
    name: "                Door Solutions                                                                                                          			",
 
    
    
    number: "3",
    category: "Door Design",
    url: "img/portfolio/200.jpg",
  },

  {
    id: 175,
    name: "              Classic Bathroom                                                                                                         			",
 
    
    
    number: "3",
    category: "Bathroom Design",
    url: "img/portfolio/175.jpg",
  },
  {
    id: 176,
    name: "             Classic Bathroom                                                                                                     			",
 
    
    
    number: "3",
    category: "Bathroom Design",
    url: "img/portfolio/176.jpg",
  },

  {
    id: 177,
    name: "              Modern Bathroom                                                                                        			",
 
    
    
    number: "3",
    category: "Bathroom Design",
    url: "img/portfolio/177.jpg",
  },

  {
    id: 178,
    name: "             Modern Bathroom                                                                                                           			",
 
    
    
    number: "3",
    category: "Bathroom Design",
    url: "img/portfolio/178.jpg",
  },

  {
    id: 179,
    name: "            TV Units                                                                                                                                    			",
 
    
    
    number: "3",
    category: "Interior Design",
    url: "img/portfolio/179.jpg",
  },
  {
    id: 180,
    name: "               Bedrooms                                                                                                                 			",
 
    
    
    number: "3",
    category: "Interior Design",
    url: "img/portfolio/180.jpg",
  },
  
  {
    id: 181,
    name: "             Dressing Rooms                                                                                                                                                                               			",
 
    
    
    number: "3",
    category: "Interior Design",
    url: "img/portfolio/181.jpg",
  },
  // {
  //   id: 182,
  //   name: "              Dressing Rooms                                                                                                                                                            			",
 
    
    
  //   number: "3",
  //   category: "Interior Design",
  //   url: "img/portfolio/182.jpg",
  // },
  {
    id: 183,
    name: "            Hotel Room Designs and Applications                                                                                                                                                                                   			",
 
    
    
    number: "3",
    category: "Interior Design",
    url: "img/portfolio/183.jpg",
  },

  {
    id: 184,
    name: "            Hotel Room Designs and Applications                                                                                                                                                                               			",
 
    
    
    number: "3",
    category: "Interior Design",
    url: "img/portfolio/184.jpg",
  },

  {
    id: 186,
    name: "           Office Design and Applications                                                                                                                                                                  			",
 
    
    
    number: "3",
    category: "Interior Design",
    url: "img/portfolio/186.jpg",
  },

  {
    id: 187,
    name: "              Hotel Room Designs and Applications                                                                                                                                                                                                                			",
 
    
    
    number: "3",
    category: "Interior Design",
    url: "img/portfolio/187.jpg",
  },
  {
    id: 188,
    name: "          Hotel Room Designs and Applications                                                                                                                                                                                                                         			",
 
    
    
    number: "3",
    category: "Interior Design",
    url: "img/portfolio/188.jpg",
  },
  {
    id: 201,
    name: "        Hotel Room Designs and Applications                                                                                                                                                                                                                       			",
 
    
    
    number: "3",
    category: "Interior Design",
    url: "img/portfolio/201.jpg",
  },
  {
    id: 189,
    name: "                             Outdoor Designs and Applications                                                                                                                                                                                                            			",
 
    
    
    number: "3",
    category: "Exterior Design",
    url: "img/portfolio/189.jpg",
  },
 


  {
    id: 203,
    name: "                      Shower Enclosure                                                                                                                                                                                                                         			",
 
    
    
    number: "3",
    category: "Bathroom (Bathtub & Shower)",
    url: "img/portfolio/203.jpg",
  },

  {
    id: 204,
    name: "                     Compact Systems                                                                                                                                                                                                                        			",
 
    
    
    number: "3",
    category: "Bathroom (Bathtub & Shower)",
    url: "img/portfolio/204.jpg",
  },
 
  {
    id: 205,
    name: "                  Special Bathtubs                                                                                                                                                                                                                        			",
 
    
    
    number: "3",
    category: "Bathroom (Bathtub & Shower)",
    url: "img/portfolio/205.jpg",
  },
 
  {
    id: 206,
    name: "              Classic Bathtubs                                                                                                                                                                                                                             			",
 
    
    
    number: "3",
    category: "Bathroom (Bathtub & Shower)",
    url: "img/portfolio/206.jpg",
  },
 
  {
    id: 207,
    name: "               Shower Trays                                                                                                                                                                                                                                                                                       			",
 
    
    
    number: "3",
    category: "Bathroom (Bathtub & Shower)",
    url: "img/portfolio/207.jpg",
  },
 
  {
    id: 208,
    name: "                     Bathroom Accessories                                                                                                                                                                                                                              			",
 
    
    
    number: "3",
    category: "Bathroom (Bathtub & Shower)",
    url: "img/portfolio/208.jpg",
  },
 
  {
    id: 209,
    name: "                 Bathroom Accessories                                                                                                                                                                                                                             			",
 
    
    
    number: "3",
    category: "Bathroom (Bathtub & Shower)",
    url: "img/portfolio/209.jpg",
  },
 
 





 


];
const categorylist = [
  { id: 1, category: "All", value: "All" },
  { id: 2, category: "PURE PLANT OILS", value: "PURE PLANT OILS" },
  { id: 3, category: "SPECIAL PLANT OILS", value: "SPECIAL PLANT OILS" },
  { id: 4, category: "SKIN CARE ", value: "SKIN CARE " },
  { id: 5, category: "HAIR CARE", value: "HAIR CARE" },
  { id: 6, category: "NATURAL SOAPS", value: "NATURAL SOAPS" },
  { id: 6, category: "INDUSTRIAL PAPER PRODUCTS", value: "INDUSTRIAL PAPER PRODUCTS" },
  { id: 7, category: "PVC Marble Panels", value: "PVC Marble Panels" },
  { id: 8, category: "Kitchen Design", value: "Kitchen Design" },
  { id: 9, category: "Door Design", value: "Door Design" },
  { id: 10, category: "Bathroom Design", value: "Bathroom Design" },
  { id: 11, category: "Interior Design", value: "Interior Design" },

  { id: 12, category: "Exterior Design", value: "Exterior Design" },
  { id: 13, category: "Bathroom (Bathtub & Shower)", value: "Bathroom (Bathtub & Shower)" },

  // { id: 12, category: "Lake ve PVC Boyama Dolap Kapağı", value: "Lake ve PVC Boyama Dolap Kapağı" },
  
];



function Portfolio() {
  const [filter, setFilter] = useState("All");

  const filteredPortfolio = useMemo(() => {
    if (filter === "All") return portfoliolist;

    return portfoliolist.filter((portfolio) => portfolio.category === filter);
  }, [filter]);

  return (
    <div className="text-center" id="portfolio">
      <Container>
        <Row>
          <Col md="12">
            <ul  className="filter-name little-margin nav navbar-nav ">
              {categorylist.map((types) => (
                <li className="filter-name btn-info active">
                  <a  alt="product"  className="filter-name color " onClick={() => setFilter(types.category)}>
                    {types.category}
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
             
        <Row>
          {filteredPortfolio.map((item) => (
            <Col active md="4" className="mb-4 " key={item.id}>
              <PortfolioItem className="little-margin hover-bg hover-text" item={item} />
              
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Portfolio;
