import React, { Component } from "react";
import AltLogo from "../../src/altLogo.png";
import enIcon from "../../src/enIcon.png";
import trIcon from "../../src/trIcon.png";
import history from "../History"



export class EnNavigationAnasayfa extends Component {
  render() {
    return (
      <nav id="menu" className="navbar navbar-default navbar-fixed-top">
        <div className="container">
          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#bs-example-navbar-collapse-1"
            >
              {" "}
              <span className="sr-only">Toggle navigation</span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
              <span className="icon-bar"></span>{" "}
            </button>
            <a className=" page-scroll " href="/">
              <img className="logo icon-bar" src={AltLogo} alt="alt Logo" />
            </a>
          </div>

          <div
            className="collapse navbar-collapse"
            id="bs-example-navbar-collapse-1"
          >
            <ul className="nav navbar-nav navbar-right">
              {" "}
              <li>
                <a
                  href="../"
                  className="page-scroll"
                >
                  About Us
                </a>
              </li>
              <li>
                <a
                  href="../"
                  className="page-scroll"
                >
                  Our Core Values
                </a>
              </li>
              <li>
                <a
                  href="../"
                  className="page-scroll"
                >
                  Corporate
                </a>
              </li>
              {/* <li>
                <a href="#services" className="page-scroll">
                  Hizmetlerimiz
                </a>
              </li> */}
              <li>
                <a
                  href="../"
                  className="page-scroll"
                >
                  Products{" "}
                </a>
              </li>
              <li>
                <a
                  href="../"
                  className="page-scroll"
                >
                  Customer Comments
                </a>
              </li>
              <li>
                <a
                  href="../"
                  className="page-scroll"
                >
                  Founder's Message{" "}
                </a>
              </li>
              <li>
              <button style={{marginLeft:"0"}} className="page-scroll" onClick={() => history.push("/")}>
                  <img style={{width:"25",margin:"1"}} src={trIcon} alt="english icon"/>
                </button>
              
              </li>
              <li>
              
  <button style={{marginLeft:"0"}} className="page-scroll" onClick={() => history.push("/English")}>
                  <img style={{width:"25",margin:"1"}} src={enIcon} alt="english icon"/>
                </button>


               
              </li>
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}

export default EnNavigationAnasayfa;
