import React, { Component } from "react";

export class Team extends Component {
  render() {
    return (
      <div id="team" className="text-center">
        <div className="container">
          <div className="col-md-8 col-md-offset-2 section-title">
            <h2>Kurucunun Mesajı</h2>
          </div>
          <div id="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-md-3 col-sm-6 team"
                  >
                    <div className="thumbnail">
                      {" "}
                      <img src={d.img} alt="..." className="team-img element-circle" />
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p className="siyah-yazi">{d.job}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
          <div id="row">
            <div className="thumbnail col-md-9 col-sm-6 team ">
              {" "}
              <p>

              <div className="col-md-2 saygilarimla"> 
<div className="saygilarimla">
  Merhaba,
</div>
</div>
             <br/> <br/>

 

Gelişen teknoloji sayesinde dünya küçüldü, en uzak noktaya, her türlü bilgiye kolay ve çabuk ulaşılabiliyor.

 

Elimizdeki bu imkanları kullanarak ticaretimizi geliştirmek, yeni pazarlara açılmak artık çok kolay.

Sadece ürettiğini yurt içinde satmak yerine yurt dışında yeni müşteri arayışı önem kazandı.

Yerel ekonomik şartlardan etkilenmemek, döviz girdisi sağlamak, nakit akımını dengelemek amacıyla ürününüzü yurtdışında satmalısınız.

 

Bu aşamada ALT Danışmanlık olarak size yardımcı olmak isteriz.

Üretici iseniz, şirketinizi detaylı olarak bize tanıtın. Sizi ve ürünlerinizi önce biz tanıyalım, sonra yurtdışı pazarlarda sizin için tanıtalım, müşteri bulalım.



Bunun için giriş sayfamızdaki “Detaylı İletişim” butonunu tıklayınız. <br/><br/>

<br/><br/><br/><br/>

              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Team;
