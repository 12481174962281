import React, { Component } from "react";
import history from "../History";

export class Gallery extends Component {



  render() {
    
    


   

  

    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Products</h2>
            <p>We act as intermediary between the (manufacturing) companies we give consulting services and importers which need their products.  The product range is not limited; we can conduct market research for any kind of products and services importers need. Some examples can be found below:
              <br/>
              <br/>
              • Woodworking <br/>
• Herbal Oils, Natural Cosmetics, Food Supplements, Soaps <br/>
• Industrial Paper Products <br/>
• PVC Marble Panels <br/>
• Bathtub & Shower
            </p>
          </div>
          <div className="row">
            <div className="portfolio-items">
           
            <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <button
               
              className="btn-custom  galery-buttons btn-lg btn"
            >
             Natural Oils
            </button>
                    <a
                    target="blank"
                      href="img/portfolio/1.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>NATURAL OILS</h4>
                      </div>
                      <img
                        src="img/portfolio/1.jpg"
                        className=""
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}    <button
               
              className="btn-custom  galery-buttons btn-lg btn"
            >
Natural Cosmetics            </button>
                    <a
                    target="blank"
                      href="img/portfolio/99.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4> NATURAL COSMETICS</h4>
                      </div>
                      <img
                        src="img/portfolio/99.jpg"
                        className=""
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}    <button
               
              className="btn-custom  galery-buttons btn-lg btn"
            >
Natural Soaps            </button>
                    <a
                    target="blank"
                      href="img/portfolio/140.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>NATURAL SOAPS</h4>
                      </div>
                      <img
                        src="img/portfolio/140.jpg"
                        className=""
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
             

              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}    <button
               
              className="btn-custom  galery-buttons btn-lg btn"
            >
Industrial Paper Products           </button>
                    <a
                    target="blank"
                      href="img/portfolio/151.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>INDUSTRIAL PAPER PRODUCTS   </h4>
                      </div>
                      <img
                        src="img/portfolio/151.jpg"
                        className=""
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>


              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}    <button
               
              className="btn-custom  galery-buttons btn-lg btn"
            >
Woodwork Design And Applications           </button>
                    <a
                    target="blank"
                      href="img/portfolio/152.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>WOODWORK DESIGN AND APPLICATIONS</h4>
                      </div>
                      <img
                        src="img/portfolio/152.jpg"
                        className=""
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            
             

              <div className="col-sm-6 col-md-4 col-lg-4 ">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}    <button
               
              className="btn-custom  galery-buttons btn-lg btn"
            >
PVC Marble Panel     </button>
                    <a
                    target="blank"
                      href="img/portfolio/163.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>PVC MARBLE PANEL
 </h4>
                      </div>
                      <img
                        src="img/portfolio/163.jpg"
                        className=""
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            
             


              <div className="">
                <button
                  onClick={() => history.push("/EnglishProducts")}
                  className="btn-custom galery-buttons-top btn-lg btn col-md-12"
                >
                  {" "}
 <b>CLICK FOR LOOKING ALL PRODUCTS.</b>             </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
