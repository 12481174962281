import React, { Component } from "react";
import history from "../History";

export class Gallery extends Component {



  render() {
    
    


   

  

    return (
      <div id="portfolio" className="text-center">
        <div className="container">
          <div className="section-title">
            <h2>Örnek Ürünlerimiz</h2>
            <p>Danışmanlığını yaptığımız üreticilerin ürünleri.</p>
          </div>
          <div className="row">
            <div className="portfolio-items">
            
            

              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}
                    <button
               
            
              className="btn-custom  galery-buttons btn-lg btn"
            >
              Bitki Yağları
            </button>
                    <a
                    target="blank"
                      href="img/portfolio/1.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>BİTKİ YAĞLARI</h4>
                      </div>
                      <img
                        src="img/portfolio/1.jpg"
                        className=""
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}    <button
               
      
              className="btn-custom  galery-buttons btn-lg btn"
            >
              Bitkisel Bakım
            </button>
                    <a
                    target="blank"
                      href="img/portfolio/99.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>BİTKİSEL BAKIM</h4>
                      </div>
                      <img
                        src="img/portfolio/99.jpg"
                        className=""
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}    <button
               
           
              className="btn-custom  galery-buttons btn-lg btn"
            >
              Doğal Sabunlar 
            </button>
                    <a
                    target="blank"
                      href="img/portfolio/140.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>DOĞAL SABUNLAR</h4>
                      </div>
                      <img
                        src="img/portfolio/140.jpg"
                        className=""
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
             

              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}    <button
               
              
              className="btn-custom  galery-buttons btn-lg btn"
            >
Kağıt Ürünler            </button>
                    <a
                    target="blank"
                      href="img/portfolio/151.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>KAĞIT ÜRÜNLER</h4>
                      </div>
                      <img
                        src="img/portfolio/151.jpg"
                        className=""
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>


              <div className="col-sm-6 col-md-4 col-lg-4">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}    <button
               
           
              className="btn-custom  galery-buttons btn-lg btn"
            >
Ahşap Tasarım Ve Uygulamaları           </button>
                    <a
                    target="blank"
                      href="img/portfolio/154.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>AHŞAP TASARIM VE UYGULAMALARI  </h4>
                      </div>
                      <img
                        src="img/portfolio/154.jpg"
                        className=""
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            
             

              <div className="col-sm-6 col-md-4 col-lg-4 ">
                <div className="portfolio-item">
                  <div className="hover-bg">
                    {" "}    <p
               
     
              className="btn-custom  galery-buttons btn-lg btn"
            >
PVC Mermer Panel       </p>
                    <a
                    target="blank"
                      href="img/portfolio/163.jpg"
                      title="Project Title"
                      data-lightbox-gallery="gallery1"
                    >
                      <div className="hover-text">
                        <h4>PVC Mermer Panel </h4>
                      </div>
                      <img
                        src="img/portfolio/163.jpg"
                        className=""
                        alt="Project Title"
                      />{" "}
                    </a>{" "}
                  </div>
                </div>
              </div>
            
             

           





              <div className="">
                <button
                  onClick={() => history.push("/Products")}
                  className="btn-custom galery-buttons-top btn-lg btn col-md-12"
                >
                  {" "}
              <b>DİĞER ÜRÜNLER İÇİN TIKLAYINIZ.</b>   
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Gallery;
