import React, { Component } from "react";

export class EnTeam extends Component {
  render() {
    return (
      <div id="team" className="text-center">
        <div className="container">
          <div className="col-md-8 col-md-offset-2 section-title">
            <h2>Founder's Message</h2>
          </div>
          <div id="row">
            {this.props.data
              ? this.props.data.map((d, i) => (
                  <div
                    key={`${d.name}-${i}`}
                    className="col-md-3 col-sm-6 team"
                  >
                    <div className="thumbnail">
                      {" "}
                      <img src={d.img} alt="..." className="team-img element-circle" />
                      <div className="caption">
                        <h4>{d.name}</h4>
                        <p className="siyah-yazi">{d.job}</p>
                      </div>
                    </div>
                  </div>
                ))
              : "loading"}
          </div>
          <div id="row">
            <div className="thumbnail col-md-9 col-sm-6 team ">
              {" "}
              <p>
              <div className="col-md-2 saygilarimla"> 
<div className="saygilarimla">
 Hello,
</div>
</div>
            <br/> <br/>

 
              Thanks to developing technology and digitalisation, the world has become one market place where information can be reached easily and quickly. Moreover, business has started to be increasingly and more efficiently conducted online.

It is now very easy to develop our trade business and open up to new markets by using these opportunities.

Finding new customers or suppliers worldwide, and thus tapping the opportunities offered by the global market place instead of limiting our business to local markets has become even more crucial,given contracting demand, increasing competition and uncertain economic conditions.

We as ALT Consulting are ready and would like to assist you in this endeavour.

If you are an importer, introduce your company and business to us in detail. Let us get to know you and your needs first. Then we will find the appropriate supplier(s) and product(s) for you.

Just click on the "Detailed Contact" button on our home page.


<br/><br/><br/><br/>

              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default EnTeam;
