import React, { Component } from "react";
import Map from "./Map";
import emailjs from 'emailjs-com';


export class EnContact extends Component {
  render() {
    function sendEmail(e) {
      e.preventDefault();
  
      emailjs.sendForm('service_zc67v09', 'template_04g821u', e.target, 'user_MCwBSw3Kfqakid39NVcdf')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    }
    return (
      <div>
        <div id="contact">
          <div className="container">
            <div className="col-md-8">
              <div className="row">
                <div className="section-title">
                  <h2>Contact Us</h2>
                  <p>
Please enter your informations for contact.                  </p>
                </div>
                <form name="sentMessage" id="contactForm" noValidate>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="text"
                          id="name"
                          className="form-control"
                          placeholder="Name"
                          required="required"
                          name="name"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <input
                          type="email"
                          id="email"
                          className="form-control"
                          placeholder="E-mail"
                          required="required"
                          name="email"
                        />
                        <p className="help-block text-danger"></p>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <textarea
                      name="message"
                      id="message"
                      className="form-control"
                      rows="4"
                      placeholder="Message"
                      required
                      
                    ></textarea>
                    <p className="help-block text-danger"></p>
                  </div>
                  <div id="success"></div><button type="reset" className="btn btn-custom btn-lg">
Clear                  </button>
                  <button type="submit" className="btn btn-custom btn-lg">
                  Submit
                  </button>
                  
                </form>
              </div>
            </div>
            <div className="col-md-3 col-md-offset-1 contact-info">
              <div className="contact-item">
                <h3>Detailed Contact</h3>
                
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Phone
                  </span>{" "}
                  {this.props.data ? this.props.data.phone : "loading"}
                </p>
              </div>

              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-phone"></i> Adress
                  </span>{" "}
                 Atakent 3.Etap Papatya Çıkmazı B43 Blok D:23 34303 Küçükçekmece/Istanbul/Türkiye
                </p>
              </div>
              <div className="contact-item">
                <p>
                  <span>
                    <i className="fa fa-envelope-o"></i> E-mail
                  </span>{" "}
                  {this.props.data ? this.props.data.email : "loading"}
                  leventtumer@alttrading.com.tr
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="social">
                  <ul>
                    <li>
                      <a
                        href={this.props.data ? this.props.data.facebook : "/"}
                      >
                        <i className="fa fa-facebook"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.twitter : "/"}>
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={this.props.data ? this.props.data.youtube : "/"}>
                        <i className="fa fa-youtube"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Map/>
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2020-Agust Created by{" "}
              <a
                className="user__colorfull"
                href="http://www.instagram.com/oguzzsancaktar"
                target="blank"
                rel="nofollow"
              >
                <b>
                  <i>Oğuz Taha Sancaktar</i>
                </b>
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default EnContact;
