import React, { Component } from 'react'
import '../../src/map.css'

export default class Map extends Component {
    render() {
        return (
            <div className="map" >
                <iframe style={{width:"100%"}} title="map" src="https://www.google.com/maps/embed?pb=!1m10!1m8!1m3!1d6016.923631603308!2d28.7842733!3d41.0588969!3m2!1i1024!2i768!4f13.1!5e0!3m2!1str!2str!4v1600857345007!5m2!1str!2str" width="600" height="450" frameborder="0" ></iframe>
            </div>
        )
    }
}
