import React, { useState, useMemo } from "react";
import { withRouter } from "react-router";
import { Container, Row, Col } from "reactstrap";
import PortfolioItem from "./PortfolioItem";

const portfoliolist = [

  // 100% SAF BİTKİ YAĞLARI 100% SAF BİTKİ YAĞLARI   100% SAF BİTKİ YAĞLARI  100% SAF BİTKİ YAĞLARI
  // 100% SAF BİTKİ YAĞLARI
  // 100% SAF BİTKİ YAĞLARI
  // 100% SAF BİTKİ YAĞLARI


  {
    id: 1,
    name: "Hindistan Cevizi Soğuk Press 50ml",
    description: " Hindistan cevizi yağı, çiğ ve olgun Hindistan cevizinden soğuk sıkım yöntemiyle elde edilen bir bitkisel yağdır. Tropik bölgelerde yetişen hindistan cevizi, en çok tercih edilen doğal çözümlerden biridir. Genellikle, cilt bakımı için tüketiciler tarafından kullanılmaktadır. Güzellik ve kozmetik alanlarında da ürün içeriklerinde sıkça rastlanmaktadır. Destek Hindistan Cevizi",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/1.jpg",
  },
  {
    id:2,
    name: "Çörek Otu Yağı 20ml",
    description: " Çörek otu yağı, Nigella sativa bitkisinden elde edilmektedir. Bu bitkinin siyah tohumları, soğuk sıkım yöntemi kullanıldığında çörek otu yağının elde edilmesini sağlar. Çörek otu yağının içerisinde “Timokinon” adı verilen bir etken madde bulunmaktadır. Vücut için esas faydayı sağlayan, Timokinon etken maddesidir. Yerli çörek otu tohumlarında oldukça yüksek miktarda Timokinon bulunduğu bilinmektedir.    ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/2.jpg",
  },
  {
    id:3,
    name: "Sarı Kantaron Yağı 50ml",
    description: " Sarı kantaron yağı, sarı kantaron bitkisinden elde edilen bir bitkisel yağdır. Sarı çiçekleriyle tanınan bu bitki, Yeni Zelanda’da oldukça yaygındır. Sarı kantaron, Hypericaceae familyasına ait bir türdür. Son yıllarda ülkemizde de çok tercih edilen bir bitkisel yağ olan sarı kantaron yağı, birçok alanda kullanılmakta ve faydaları bilinmektedir. Destek Sarı Kantaron Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Meserasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/3.jpg",
  },
  {
    id:4,
    name: "Tatlı Badem Yağı Soğuk Pres 20ml",
    description: " Tatlı badem yağı, badem ağacı tohumlarından soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Hafif kokuludur ve tat bakımından ceviz ile benzerlik gösterir. Cilt bakımı ve saç bakımı için en çok tercih edilen bitkisel yağlardan biridir. Latince adı Prunus Amygdalus Dulcis olan tatlı badem yağı, içeriğinde %68 oleik asit, %25 linoleik asit, %4 palmitik asit bulundurur. Tatlı badem yağı aynı zamanda bol miktarda A ve K vitaminlerini içermektedir. Destek Tatlı Badem Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/4.jpg",
  },
  {
    id:5,
    name: "Susam Yağı Soğuk Pres 20ml",
    description: " Susam yağı, susam tohumlarından elde edilen bir bitkisel yağdır. Birçok farklı amaçla kullanılabilen susam yağı; saçlara, cilde ve deriye uygulanabilir. Destek Susam Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/5.jpg",
  },
  {
    id:6,
    name: "Acı Biber Yağı Soğuk Pres 20ml",
    description: " Acı biber yağı, acı biberden soğuk sıkım yöntemiyle elde edilen bir bitkisel yağdır. İçeriğinde “Capsicum frutescens” bulunur. Acı biberden elde edilen bu yağ, etken maddeleri sayesinde birçok alanda kullanılır. Acı olma özelliğini kapsaisin adındaki maddeden alır. Aynı zamanda; acı biber yağı, içeriğinde A ve C vitamini bulundurur. Destek Acı Biber Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/6.jpg",
  },
  {
    id:7,
    name: "Çam Terebentin Distile 20ml",
    description: " Çam terebentin yağı, çam ağacının reçinesinden elde edilen bir bitkisel yağdır. Diğer adıyla turpentine, son zamanlarda saç bakımı için sıklıkla kullanılır. Ağaçtan distilasyon yöntemi ile elde edilen bu yağın, şampuan ile birlikte kullanımı tercih edilir. Destek Çam Terebentin Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/7.jpg",
  },
  {
    id:8,
    name: "Üzüm Çekirdeği Yağı Soğuk Pres 50ml",
    description: "  Üzüm çekirdeği yağı, üzümün çekirdeğinden soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Renksiz ve hafif bir yağdır. İçeriğinde yoğun E vitamini bulunmaktadır. Kozmetik alanında çok geniş bir kullanım alanı vardır. Aynı zamanda şarap üretiminde de kullanılmaktadır. Besin değerleri sayesinde kullanımı sıklıkla tercih edilen yağlardan biridir. Destek Üzüm Çekirdeği Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/8.jpg",
  },
  {
    id:9,
    name: "Kuşburnu Yağı Soğuk Pres 20ml",
    description: " Kuşburnu yağı, kuşburnu bitkisinden elde edilen bir bitkisel yağdır. Latince adı Rosa Canina olan kuşburnu, Avrupa’da, Kuzeybatı Afrika’da ve Batı Asya’da yetişir. Kuşburnu yağı, içeriğinde Omega 3, Omega 6 ve A, E, C vitaminlerini bulundurur. Dolayısıyla son dönemin en çok tercih edilen vitamin kaynaklarından biri olmuştur. Ayrıca temel yağ asitleri bakımından da zengin bir yapıya sahiptir. Destek Kuşburnu Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/9.jpg",
  },
  {
    id:10,
    name: "Tesbih ağacı Yağı 20ml",
    description: " Tesbih ağacı yağı, tesbih ağacından elde edilen bir bitkisel yağdır. Tesbih ağacı, genellikle Hindistan’da ve Avrupa’nın sıcak bölgelerinde yetişir. Bu bitkisel yağ, keskin bir kokuya sahiptir. Yağ asitleri ve besin değerleri bakımından zengin olan tesbih ağacı yağı, kozmetik alanında kullanılmaktadır. Saç bakımında da kullanımı mevcuttur. Genel olarak, başka yağlar ile seyreltilerek kullanılması önerilir. Destek Tesbih Ağacı Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/10.jpg",
  },
  {
    id:11,
    name: "Sarımsak Yağı Soğuk Pres 20ml",
    description: " Sarımsak yağı, soğan ailesinin bir üyesi olan sarımsaktan elde edilen bir bitkisel yağdır. Latince adı Allium Sativum olan sarımsak, tropikal bölgelerde yetişir ve keskin bir aromaya sahiptir. Sarımsak, özellikle mutfak uygulamalarında sıklıkla tercih edilmektedir. Bunun yanı sıra, içerdiği vitaminler ile cilt bakımında da kullanılabilmektedir. Geniş kullanım alanı sayesinde en çok tüketilen bitkisel yağlardan biridir. Destek Sarımsak Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/11.jpg",
  },
  
  {
    id: 12,
    name: "Aspir Yağı Soğuk Pres 250ml",
    description: " Aspir yağı, aspir bitkisinden soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Aspir, otsu bir yıllık bitkidir. Papatyagiller familyasına ait bir türdür. Yağmur alan kurak coğrafyalarda yetiştirilmektedir. Ülkemizde bazı bölgelerde de mevcuttur. Aspir yağı, E vitamini açısından zengin bir kaynaktır. İçeriğinde Omega-6 ile linoleik asit barındırır. Destek Aspir Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/12.jpg",
  },
  {
    id: 13,
    name: "At Kestanesi Yağı 250ml",
    description: " At kestanesi yağı, at kestanesi bitkisinden elde edilen bir bitkisel yağdır. Latince adı Aesculus Hippocastanum olan at kestanesi, Balkan yarımadasında yetişen bir bitkidir. Sapindaceae familyasına ait bir türdür. At kestanesi yağı, sağlık ve kozmetik alanında yaygın olarak kullanılan bir bitkisel yağdır. Destek At Kestanesi Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/13.jpg",
  },
  {
    id: 14,
    name: "Havuç Yağı Soğuk Pres 20ml",
    description: " Havuç yağı, havuç özünden soğuk sıkım yöntemiyle elde edilen bir bitkisel yağdır. İçeriğinde bulunan yüksek miktarlarda vitaminler ile sağlık ve kozmetik alanında kullanılan bir sebzedir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/14.jpg",
  },
  {
    id: 15,
    name: "Jojoba Yağı Soğuk Pres 20ml",
    description: " Jojoba yağı, Simmondsiaceae ailesindeki bitkiden elde edilen bir bitkisel yağdır. Bu familyadaki bitkiler genellikle Güney Arizona, Güney California ve Meksika’da yetişmektedir. Latince ismi Simmondsia Cinensis olan jojoba, aroma bakımından fındık ile benzerlik gösterir. Altın sarısı renkte olması, jojoba yağını çekici kılar. Son yıllarda cilt ve saç bakımı için sıklıkla tercih edilmektedir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/15.jpg",
  },
  {
    id: 16,
    name: "Saf Zeytinyağı Soğuk Pres 50ml",
    description: " Saf zeytinyağı, soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Destek Saf Zeytinyağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/16.jpg",
  },
  {
    id: 17,
    name: "Hint Yağı Soğuk Pres 50ml",
    description: "  Hint yağı, Ricinus Communis adlı bitkinin tohumlarından elde edilen bir bitkisel yağdır. Bu yağ, soluk sarı renkte ve hafif kokuludur. Bilinen birçok faydası ve kullanım alanı bulunmaktadır. Ülkemizde özellikle güzellik alanında tercih edilmektedir. Faydalı ve zengin içeriği ile kozmetik ve tıp dünyasında da sık kullanımı mevcuttur. Saç ve cilt bakımı için de kullanılmaktadır. İçeriğinde protein, çeşitli mineraller ve asitler ve E vitamini bulundurmaktadır. Destek Hint Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/17.jpg",
  },
  {
    id: 18,
    name: "Ylang Ylang Yağı Distile 20ml",
    description: " Ylang ylang yağı, Cananga Odorata adlı ağaçtan elde edilen bir bitkisel yağdır. Güney Asya ormanlarında yetişen bu ağaç, kendine özgü çiçeklere sahiptir. Halk arasında parfüm ağacı olarak da bilinir. Egzotik bir kokusu olan ylang ylang yağı, özellikle kozmetik alanında sıklıkla tercih edilmektedir. Parfüm esanslarının içeriğinde bulunabilmektedir. Ayrıca saç bakımında da kullanılmaktadır. Destek Ylang Ylang Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/18.jpg",
  },
  {
    id: 19,
    name: "Menengiç Yağı Soğuk Pres 20ml",
    description: " Menengiç yağı, menengiç ağacından soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Menengiç ağacı, İran’a özgü, ancak birçok bölgede yetişebilen bir bitki türüdür. Halk arasında çitlembik olarak da bilinen bu bitki türü, bitkisel yağ eldesinde ve kahve yapımında kullanılabilir. Son yıllarda bilinirliği artan bu yağın birçok faydası olduğu bilinmektedir. İçeriğinde yüksek oranda demir, potasyum, kalsiyum, ve A, C vitaminlerini barındırır. Destek Menengiç Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/19.jpg",
  },
  {
    id: 20,
    name: "	Menekşe Yağı 20ml",
    description: " Menekşe yağı, kurutulmuş menekşe çiçeklerinden ve yapraklarından elde edilen bir bitkisel yağdır. Bilimsel adı, iola odorata olan bitki, Fransa, İtalya, Çin ve Yunanistan’a özgüdür.  Ancal dünyanın pek çok yerinde yetiştirilen menekşe, birçok alanda kullanılabilmektedir. Menekşe yağı, tatlı ve çiçeksi bir aromaya sahiptir. A ve C vitamini bakımından oldukça zengindir. Saç ve cilt bakımında tercih edilmektedir. Destek Menekşe Yağı, %100 saf ve yerli üretimdir.",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/20.jpg",
  },
  {
    id: 21,
    name: "Lavanta Yağı Distile 10ml",
    description: " Lavanta yağı, lavanta bitkisinden distilasyon yöntemi ile elde edilen bir bitkisel yağdır. Lavanta, yüksek ve kırsal kesimlerde yetişen, mor çiçekli ve hoş kokulu bir bitkidir. Ballıbabagiller familyasına ait bir türdür. Belirgin özelliklerinden olan kokusu ve rengi, lavantayı birçok alanda kullanılabilir kılar. Lavanta yağı, tıbbi ve kozmetik alanlarında sıkça kullanılır. Ayrıca çay aroması olarak da kullanılmaktadır. Destek Lavanta Yağı, %100 saf ve yerli üretimdir.",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/21.jpg",
  },
  {
    id:22,
    name: "Karanfil Yağı Distile 10ml",
    description: " Karanfil yağı, karanfilden distilasyon yöntemi ile elde edilen bir bitkisel yağdır. Karanfil, mersingiller ailesine özgü bir türdür. Genellikle, Doğu Afrika ve Asya bölgelerinde yetişmektedir. Yağmurlu ve sıcak iklimlere uygun bir türdür. Ülkemizde de bol miktar karanfil yetişmektedir. Tıbbi ve kozmetik kullanımları bulunmaktadır. Ayrıca ağız bakımı için de tercih edilmektedir. Destek Karanfil Yağı, %100 saf ve yerli üretimdir.",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/22.jpg",
  },
  {
    id: 23,
    name: "Sarı Kantaron Yağı 20ml",
    description: " Sarı kantaron yağı, sarı kantaron bitkisinden elde edilen bir bitkisel yağdır. Sarı çiçekleriyle tanınan bu bitki, Yeni Zelanda’da oldukça yaygındır. Sarı kantaron, Hypericaceae familyasına ait bir türdür. Son yıllarda ülkemizde de çok tercih edilen bir bitkisel yağ olan sarı kantaron yağı, birçok alanda kullanılmakta ve faydaları bilinmektedir. Destek Sarı Kantaron Yağı, %100 saf ve yerli üretimdir.",
    description2:"Maserasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/23.jpg",
  },
  {
    id: 24,
    name: "Hodan Yağı Soğuk Pres 20ml",
    description: " Hodan yağı, hodan bitkisinin yaprakları ile çiçek kısımlarından soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Hodan otu, bahar ve yaz aylarında mavi renkte çiçek açar. Birçok farklı bölgede yetişebilmektedir. Hodan yağı, yüksek oranda kalsiyum, mineralli tuz ve potasyum içerir. En önemli özelliklerinden biri ise gamma linolenik asit denilen yağ asidi açısından zengin olmasıdır. Destek Hodan Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/24.jpg",
  },
  {
    id: 25,
    name: "Çay Ağacı Yağı Distile 10ml",
    description: " Çay ağacı yağı, hint defnesi adlı bitkiden elde edilen bir bitkisel yağdır. Diğer adıyla Melaleuca Alternifolia Leaf, yassı yapraklı bir bitkidir. Bitki mersingiller cinsine aittir. Avustralya’ya özgü olan bu tür, uzun yıllardır yetiştirilmesine rağmen, son zamanlarda sıkça tüketilmeye başlanmıştır. Cilt bakımında yoğun olarak kullanılmaktadır. Destek Çay Ağacı Yağı, %100 saf ve yerli üretimdir.",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/25.jpg",
  },
  {
    id: 26,
    name: "Biberiye Yağı Distile 10ml",
    description: " Biberiye yağı, biberiye bitkisinin yapraklarından elde edilen bir bitkisel yağdır. Genellikle Akdeniz ikliminde yetişmektedir. Bu yağın geçmişi, Antik Çağ’a kadar uzanmaktadır. Kozmetik ve sağlık alanlarında yaygın kullanımı mevcuttur. Destek Biberiye Yağı, %100 saf ve yerli üretimdir.",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/26.jpg",
  },
  {
    id: 27,
    name: "Üzüm Çekirdeği Yağı Soğuk Pres 250ml",
    description: " Üzüm çekirdeği yağı, üzümün çekirdeğinden soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Renksiz ve hafif bir yağdır. İçeriğinde yoğun E vitamini bulunmaktadır. Kozmetik alanında çok geniş bir kullanım alanı vardır. Aynı zamanda şarap üretiminde de kullanılmaktadır. Besin değerleri sayesinde kullanımı sıklıkla tercih edilen yağlardan biridir. Destek Üzüm Çekirdeği Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/27.jpg",
  },
  {
    id: 28,
    name: "Tatlı Badem Yağı Soğuk Pres 250ml",
    description: " Tatlı badem yağı, badem ağacı tohumlarından soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Hafif kokuludur ve tat bakımından ceviz ile benzerlik gösterir. Cilt bakımı ve saç bakımı için en çok tercih edilen bitkisel yağlardan biridir. Latince adı Prunus Amygdalus Dulcis olan tatlı badem yağı, içeriğinde %68 oleik asit, %25 linoleik asit, %4 palmitik asit bulundurur. Tatlı badem yağı aynı zamanda bol miktarda A ve K vitaminlerini içermektedir. Destek Tatlı Badem Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/28.jpg",
  },
  {
    id: 29,
    name: "Susam Yağı Soğuk Pres 250ml",
    description: " Susam yağı, susam tohumlarından elde edilen bir bitkisel yağdır. Birçok farklı amaçla kullanılabilen susam yağı; saçlara, cilde ve deriye uygulanabilir. Destek Susam Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/29.jpg",
  },
  {
    id: 30,
    name: "Saf Zeytinyağı Soğuk Pres 250ml",
    description: " Saf zeytinyağı, soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Destek Saf Zeytinyağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/30.jpg",
  },
  {
    id: 31,
    name: "Keten Tohumu Yağı Soğuk Pres 250ml",
    description: " Keten tohumu yağı, keten tohumu bitkisinden soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Yaz aylarında meyve veren bu bitki, oval ve sert bir yapıya sahiptir. Keten tohumu kullanımın çok eski çağlara dayandığı bilinmektedir. Genellikle Yakın Doğu ve Akdeniz bölgelerinde yetişmektedir. Keten tohumu yağının içeriğinde yüksek miktarda yağ asidi bulunmaktadır. Aynı zamanda çeşitli mineral ve vitaminleri de barındırmaktadır. Destek Keten Tohumu Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/31.jpg",
  },
  {
    id: 32,
    name: "Sarı Kantaron Yağı 250ml",
    description: " Sarı kantaron yağı, sarı kantaron bitkisinden elde edilen bir bitkisel yağdır. Sarı çiçekleriyle tanınan bu bitki, Yeni Zelanda’da oldukça yaygındır. Sarı kantaron, Hypericaceae familyasına ait bir türdür. Son yıllarda ülkemizde de çok tercih edilen bir bitkisel yağ olan sarı kantaron yağı, birçok alanda kullanılmakta ve faydaları bilinmektedir. Destek Sarı Kantaron Yağı, %100 saf ve yerli üretimdir.",
    description2:"Maserasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/32.jpg",
  },
  {
    id: 33,
    name: "Çörek Otu Yağı 250ml",
    description: " Çörek otu yağı, Nigella sativa bitkisinden elde edilmektedir. Bu bitkinin siyah tohumları, soğuk sıkım yöntemi kullanıldığında çörek otu yağının elde edilmesini sağlar. Çörek otu yağının içerisinde “Timokinon” adı verilen bir etken madde bulunmaktadır. Vücut için esas faydayı sağlayan, Timokinon etken maddesidir. Yerli çörek otu tohumlarında oldukça yüksek miktarda Timokinon bulunduğu bilinmektedir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/33.jpg",
  },
  {
    id: 34,
    name: "Çam Terebentin Distile 250ml",
    description: " Çam terebentin yağı, çam ağacının reçinesinden elde edilen bir bitkisel yağdır. Diğer adıyla turpentine, son zamanlarda saç bakımı için sıklıkla kullanılır. Ağaçtan distilasyon yöntemi ile elde edilen bu yağın, şampuan ile birlikte kullanımı tercih edilir. Destek Çam Terebentin Yağı, %100 saf ve yerli üretimdir.",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/34.jpg",
  },
  {
    id: 35,
    name: "Alabalık Yağı 250ml",
    description: " Alabalık yağı, alabalıktan elde edilen bir bitkisel yağdır. Alabalık, tatlı su ve denizlerde yaşayan bir balık türüdür. Bu balık türü, Omega 3 bakımından oldukça zengindir. Birçok faydası olduğu bilinmektedir. Destek Alabalık Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/35.jpg",
  },
  {
    id: 36,
    name: "Susam Yağı Soğuk Pres 50ml",
    description: " Susam yağı, susam tohumlarından elde edilen bir bitkisel yağdır. Birçok farklı amaçla kullanılabilen susam yağı; saçlara, cilde ve deriye uygulanabilir. Destek Susam Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/36.jpg",
  },
  {
    id: 37,
    name: "Lahana Yağı 50ml",
    description: " Lahana yağı, lahana sebzesinden elde edilen bir bitkisel yağdır. Kış sebzelerinin başında gelen lahana, özellikle mutfak uygulamalarında sıklıkla kullanılmaktadır. Lahana yağı içeriğinde B6, A, C ve K vitaminlerini bulundurur. Cilt bakımı için çeşitli uygulamaları mevcuttur. Destek Lahana Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/37.jpg",
  },
  {
    id: 38,
    name: "Keten Tohumu Yağı Soğuk Pres 50ml",
    description: " Keten tohumu yağı, keten tohumu bitkisinden soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Yaz aylarında meyve veren bu bitki, oval ve sert bir yapıya sahiptir. Keten tohumu kullanımın çok eski çağlara dayandığı bilinmektedir. Genellikle Yakın Doğu ve Akdeniz bölgelerinde yetişmektedir. Keten tohumu yağının içeriğinde yüksek miktarda yağ asidi bulunmaktadır. Aynı zamanda çeşitli mineral ve vitaminleri de barındırmaktadır. Destek Keten Tohumu Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/38.jpg",
  },
  {
    id: 39,
    name: "Kakao Yağı (katı) 50ml",
    description: " Kakao yağı, kakao çekirdeklerinden soğuk sıkım yöntemiyle elde edilen bir bitkisel yağdır. Kakao, Afrika ormanlarında yetişen, yüksek besin değerleri içeren bir meyvedir. Birçok kozmetik ürününün içeriğinde kullanılan kakao yağı, farklı alanlarda da tercih edilmektedir. Latince ismi ile Theobroma Cacao Seed Butter katı halde satılmaktadır. İçerdiği yağ asitleri sayesinde tercih edilen bitkisel yağlardan biridir. Destek Kakao Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/39.jpg",
  },
  {
    id: 40,
    name: "Haşhaş Yağı Soğuk Pres 50ml",
    description: " Haşhaş yağı, haşhaş tohumundan soğuk sıkım yöntemi ile elde edilmektedir. Doğu Akdeniz ve Asya kökenli olan bu bitki, papaveraceae familyasına ait bir türdür. Yüksek miktarda E vitamini içermektedir. Ayrıca diğer bileşenler ve mineraller açısından da zengin bir bitkisel yağdır. Destek Haşhaş Yağı, %100 saf ve yerli üretimdir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/40.jpg",
  },
  {
    id: 41,
    name: "Hardal Yağı Soğuk Pres 50ml",
    description: " Hardal yağı, hardal bitkisinin tohum kısımlarından soğuk sıkım yöntemiyle elde edilen bir bitkisel yağdır. Antik Yunan döneminden beri kullanılan hardal yağının, birçok alanda faydalı olduğu bilinmektedir. Omega-3 ve Omega-6 yağ oranları bakımından dengelidir. Kendine özgü bir kokusu bulunmaktadır. Hardal yağı, son zamanlarda saç bakımı için de sıklıkla tercih edilmektedir. Hardal Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/41.jpg",
  },
  {
    id: 42,
    name: "Fındık Yağı Soğuk Pres 50ml",
    description: " Fındık yağı, corylus ismi verilen ağacın meyvesinden elde edilen bir bitkisel yağdır. Bu ağaç Kuzey yarım küreye özgüdür. Fındık, tarihi ve kullanımı açısından çok eski çağlara dayanmaktadır. Ülkemizde de özellikle Karadeniz bölgesinde yoğun olarak üretilmektedir. İçerdiği vitaminler ve yüksek besin değerleri ile geniş bir kullanım alanı bulunmaktadır. Destek Fındık Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/42.jpg",
  },
  {
    id: 43,
    name: "Çörek Otu Yağı 50ml",
    description: " Çörek otu yağı, Nigella sativa bitkisinden elde edilmektedir. Bu bitkinin siyah tohumları, soğuk sıkım yöntemi kullanıldığında çörek otu yağının elde edilmesini sağlar. Çörek otu yağının içerisinde “Timokinon” adı verilen bir etken madde bulunmaktadır. Vücut için esas faydayı sağlayan, Timokinon etken maddesidir. Yerli çörek otu tohumlarında oldukça yüksek miktarda Timokinon bulunduğu bilinmektedir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/43.jpg",
  },
  {
    id: 44,
    name: "Çam Terebentin Distile 50ml",
    description: " Çam terebentin yağı, çam ağacının reçinesinden elde edilen bir bitkisel yağdır. Diğer adıyla turpentine, son zamanlarda saç bakımı için sıklıkla kullanılır. Ağaçtan distilasyon yöntemi ile elde edilen bu yağın, şampuan ile birlikte kullanımı tercih edilir. Destek Çam Terebentin Yağı, %100 saf ve yerli üretimdir.",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/44.jpg",
  },
  {
    id: 45,
    name: "Ceviz Yağı Soğuk Pres 50ml",
    description: "  Ceviz yağı, ceviz meyvesinden soğuk sıkım yöntemiyle elde edilen bir bitkisel yağdır. Latince ismi “Juglans regia” olan ceviz yağı, Omega-3 bakımından zengin bir içeriğe sahiptir. İçeriğinde çeşitli mineral ve vitaminleri de barındıran ceviz yağı, kozmetikten medikale birçok alanda kullanılabilmektedir. Destek Ceviz Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/45.jpg",
  },
  {
    id: 46,
    name: "Alabalık Yağı 50ml",
    description: "  Alabalık yağı, alabalıktan elde edilen bir bitkisel yağdır. Alabalık, tatlı su ve denizlerde yaşayan bir balık türüdür. Bu balık türü, Omega 3 bakımından oldukça zengindir. Birçok faydası olduğu bilinmektedir. Destek Alabalık Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/46.jpg",
  },
  {
    id: 47,
    name: "Yabani İğde Yağı 50ml",
    description: " Yabani iğde yağı, Uzak Doğu’da uzun yıllardır kullanılan bir bitkisel yağdır. Doğadaki tek Omega 7 kaynağı olarak bilinen yabani iğde, birçok alanda kullanılmaktadır. Latince ismi Oleum Hippophae Rhamnoides’dir. Destek Yabani İğde Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/47.jpg",
  },
  {
    id: 48,
    name: "Tere Tohumu Yağı Soğuk Pres 20ml",
    description: "  Tere tohumu yağı, tere tohumundan soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Tere tohumu, veya Lepidium Sativum, turpgiller familyasına ait bir türdür. Genellikle Asya ve Kuzey Afrika’da yetişmektedir. Ülkemizde hemen her bölgede yetişen tere tohumu, koyu kırmızı renge ve acımsı bir tada sahiptir. Bu tohumdan elde edilen bitkisel yağ, birçok kozmetik ürününün içeriğinde yer almaktadır. Tere tohumu yağ, içeriğinde karbonhidratlar, lipitler ve bazı vitaminleri barındırmaktadır. Destek Tere Tohumu Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/48.jpg",
  },
  {
    id: 49,
    name: "Şeftali Yağı Soğuk Pres 20ml",
    description: " Şeftali yağı, şeftali meyvesinin çekirdeğinden soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Şeftali, B ve C vitaminleri bakımından zengin bir meyvedir. Şeftali yağı, soğuk sıkım yöntemiyle elde edildiği için, besin değerleri korunmaktadır. Tek başına veya çeşitli uçucu yağlar ile kullanılabilmektedir. Cilt ve vücut bakımı için uygundur. Destek Şeftali Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/49.jpg",
  },
  {
    id: 50,
    name: "Shea Yağı Soğuk Pres 20ml",
    description: " Shea yağı, shea ağacının fındığından soğuk sıkım yöntemiyle elde edilen bir bitkisel yağdır. Shea ağacı, Afrika’da yetişen tropik bir ağaçtır. Uzun ömürlü olan bu ağaç, 15-20 yıl içinde meyve verebilmektedir. Shea yağı, searik asit ve oleik asitten gelen trigliserit yapıdadır. İçeriğinde A, D, E ve F vitaminlerini barındırır. Koku bakımından hindistan cevizi ile benzerlik gösterir. Kozmetik alanında farklı kullanımları bulunmaktadır. Destek Shea Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/50.jpg",
  },
  {
    id: 51,
    name: "Sedir Ağacı Yağı Distile 20ml",
    description: " Sedir ağacı yağı, sedir ağacından distilasyon yöntemi ile elde edilen bir bitkisel yağdır. İğne yapraklı bir ağaç türüdür. Ağaç özünden oluşan sedir ağacı yağı, daha çok kozmetik alanında kullanılmaktadır. Yağ, içeriğinde alfa-sedrin, beta-sedrene, sedrol, widdrol, thujopsen gibi bieşenleri barındırır. Odunsu bir kokuya sahip olan bu yağ, birçok kozmetik ürününün içeriğinde bulunur. Destek Sedir Ağacı Yağı, %100 saf ve yerli üretimdir.",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/51.jpg",
  },
  {
    id: 52,
    name: "Rezene Yağı Soğuk Pres 20ml",
    description: " Rezene yağı, rezene bitkisinden soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Latince adı Foeniculum Vulgare olan rezene, havuç familyasına ait çiçekli bir bitki türüdür. Oldukça dayanıklı olan bu bitki, genellikle kıyı bölgelerinde yetişmektedir. Tüylü yaprakları ve sari çiçekleriyle ilgi çekici bir bitkidir. Sağlık alanında çeşitli kullanımları olan rezene yağı, bol miktarda vitamin ve mineral içermektedir. Destek Rezene Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/52.jpg",
  },
  {
    id: 53,
    name: "Portakal Yağı Distile 20ml",
    description: " Portakal yağı, portakal kabuğundan distilasyon yöntemi ile elde edilen bir bitkisel yağdır. Turunçgiller ailesinden olan portakal, C vitamini bakımından oldukça zengin bir meyvedir. İçeriğindeki organik asitler ve mineraller sayesinde birçok alanda kullanılabilmektedir. Destek Portakal Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/53.jpg",
  },
  {
    id: 54,
    name: "Pirinç Kepeği Yağı 20ml",
    description: "  Pirinç kepeği yağı, pirincin dış kabuğu ile çekirdeği arasındaki sert ve yağlı tabakadan elde edilen bir bitkisel yağdır. Bu kısma aynı zamanda kepek adı da verilir. Dünya genelinde sıkça tüketilen bu bitkisel yağın çoğunluğunu Asya kıtasındaki ülkeler almaktadır. Vücudun ihtiyaç duyduğu vitamin ve mineralleri içerisinde barındıran pirinç kepeği yağı, cilt ve saç bakımında kullanılabilmektedir. Destek Pirinç Kepeği Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/54.jpg",
  },
  {
    id: 55,
    name: "Pelesenk Yağı 20ml",
    description: " Pelesenk yağı, pelesenk ağacından elde edilen bir bitkisel yağdır. Pelesenk ağacı genellikle Karayip adaları, Güney Asya, Uzak Doğu ve Amerika’da bulunan ve 15 metre uzunluğuna kadar çıkabilen bir ağaç türüdür. Yağ, pelesenk ağacının gövdesinden elde edilmektedir. Diğer adıyla gül ağacı yağı, ılık ve canlandırıcı bir aromaya sahiptir. Kokusu ve aroması sayesinde parfümeri ve sabun üretimi alanlarında sıkça kullanılmaktadır. Destek Pelesenk Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/55.jpg",
  },
  {
    id: 56,
    name: "Papatya Yağı Distile 20ml",
    description: " Papatya yağı, papatya bitkisinin çiçeklerinden elde edilen bir bitkisel yağdır. Papatya, yaygın olarak Akdeniz bölgesinde yetişen, 50 farklı çeşidi bulunan bir bitkidir. Papatya yağı cilt bakımı için kullanılmaktadır. Destek Papatya Yağı, %100 saf ve yerli üretimdir.",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/56.jpg",
  },
  {
    id: 57,
    name: "Okaliptus Yağı Distile 20ml",
    description: " Okaliptüs yağı, okaliptüs ağacı yapraklarından elde edilen bir bitkisel yağdır. Yüzlerce okaliptüs olmasına karşın, bitkisel yağlar için Eucalyptus salicifolia ve Eucalyptus globulus tercih edilmektedir. Avustralya kökenli bir bitkidir. İçeriğindeki sineyol maddesi sayesinde birçok konuda etkili olduğu bilinmektedir. Ülkemizde de kozmetik alanında sıklıkla kullanılmaya başlanmıştır. Destek Okaliptüs Yağı, %100 saf ve yerli üretimdir.",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/57.jpg",
  },
  {
    id: 58,
    name: "Okaliptus Yağı Distile 10ml",
    description: " Okaliptüs yağı, okaliptüs ağacı yapraklarından elde edilen bir bitkisel yağdır. Yüzlerce okaliptüs olmasına karşın, bitkisel yağlar için Eucalyptus salicifolia ve Eucalyptus globulus tercih edilmektedir. Avustralya kökenli bir bitkidir. İçeriğindeki sineyol maddesi sayesinde birçok konuda etkili olduğu bilinmektedir. Ülkemizde de kozmetik alanında sıklıkla kullanılmaya başlanmıştır. Destek Okaliptüs Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/58.jpg",
  },
  {
    id: 59,
    name: "Nane Yağı Distile 20ml",
    description: " Nane yağı, naneden elde edilen bir bitkisel yağdır. Dünyanın her yerinde kolaylıkla yetişebilen nane, körpe yapraklarından hazırlandığında daha faydalı bir bitkisel yağ oluşumu sağlıyor. Nane, aynı zamanda vücudumuzda sentezleyemediğimiz bazı yağ asitlerini de içermektedir. Oldukça kullanışlı bir yağdır. Destek Nane Yağı, %100 saf ve yerli üretimdir.",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/59.jpg",
  },
  {
    id: 60,
    name: "Nane Yağı Distile 10ml",
    description: "  Nane yağı, naneden elde edilen bir bitkisel yağdır. Dünyanın her yerinde kolaylıkla yetişebilen nane, körpe yapraklarından hazırlandığında daha faydalı bir bitkisel yağ oluşumu sağlıyor. Nane, aynı zamanda vücudumuzda sentezleyemediğimiz bazı yağ asitlerini de içermektedir. Oldukça kullanışlı bir yağdır. Destek Nane Yağı, %100 saf ve yerli üretimdir.",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/60.jpg",
  },
  {
    id: 61,
    name: "Maydanoz Yağı Soğuk Pres 20ml",
    description: " Maydanoz yağı, maydanoz tohumundan soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Bu yağ, aynı zamanda maydanozun köklerinden ve yapraklarından da elde edilebilmektedir. Bol miktarda A, C ve K vitamini içeren maydanoz, kalsiyum, magnezyum gibi mineraller bakımından da zengindir. Sağlık alanında kullanıldığı gibi, cilt ve saç bakımında da tercih edilmektedir. Bilinen birçok faydası ile, son zamanlarda en çok tüketilen bitkisel yağlardan biridir. Destek Maydanoz Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/61.jpg",
  },
  {
    id: 62,
    name: "Macadamia Yağı Soğuk Pres 20ml",
    description: " Macadamia yağı, fındıkgiller ailesine ait macadamia meyvesinden elde edilen bir bitkisel yağdır. Avustralya ve doğu sahillerinde üretilen bu fındık, ülkemizdeki fındığın boyutuna oranla oldukça büyüktür. Macadamia meyvesi, başka hiçbir meyvede bulunmayan palmitoleik asidi içerir. İçeriği sayesinde oldukça önemli etkileri olan macadamia yağı, özellikle cilt bakımı için kullanılır. Destek Macadamia Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/62.jpg",
  },
  {
    id: 63,
    name: "Limon Yağı Distile 20ml	",
    description: " Limon yağı, limon kabuklarından elde edilen bir bitkisel yağdır. Limonda %68 oranında bulunan d-limonen maddesi sayesinde limon, birçok alanda etkilidir. C vitamini bakımından zengin olan bu yağ; sağlık, temizlik ve kozmetik alanlarında sıklıkla tercih edilmektedir. Destek Limon Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/63.jpg",
  },
  {
    id: 64,
    name: "Lavanta Yağı Distile 20ml",
    description: " Lavanta yağı, lavanta bitkisinden distilasyon yöntemi ile elde edilen bir bitkisel yağdır. Lavanta, yüksek ve kırsal kesimlerde yetişen, mor çiçekli ve hoş kokulu bir bitkidir. Ballıbabagiller familyasına ait bir türdür. Belirgin özelliklerinden olan kokusu ve rengi, lavantayı birçok alanda kullanılabilir kılar. Lavanta yağı, tıbbi ve kozmetik alanlarında sıkça kullanılır. Ayrıca çay aroması olarak da kullanılmaktadır. Destek Lavanta Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/64.jpg",
  },
  {
    id: 65,
    name: "Keklik Üzümü Yağı Distile 20ml",
    description: "  Keklik üzümü yağı, Gaultheria Procumbens adlı bitkiden distilasyon yöntemiyle elde edilen bir bitkisel yağdır. Genellikle Kuzey Amerika bölgesinde yetişen bu bitkinin kendine has bir kokusu vardır. Keklik üzümünün yapraklarından elde edilen bu yağ, aynı zamanda çay yapımında da kullanılır. Bu yağın temel bileşenleri metal salisilat ve gaulterilendir. Keklik üzümü yağı, aromaterapilerde ve çeşitli kozmetik ürünlerinde kullanılmaktadır. Destek Keklik Üzümü Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/65.jpg",
  },
  {
    id: 66,
    name: "Kekik Yağı Distile 20ml",
    description: " Kekik yağı, kekikten elde edilen bir bitkisel yağdır. Kekik, daha çok ormanlarda, tarlalarda ve çimenlik alanlarda yetişen bir bitki türüdür. Sıcak iklimlerde yetişen bir türdür. Genellikle mutfak uygulamalarında, baharat olarak kullanılmaktadır. Bilimsel adı ile Thymus Vulgaris Leaf Oil, ağız bakımında, aromaterapide ve kozmetik ürünlerde kullanılmaktadır. Kendine özgü kokusunun yanı sıra, zengin içeriği ile en çok tercih edilen bitkisel yağlardan biridir. Destek Kekik Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/66.jpg",
  },
  {
    id: 67,
    name: "Kekik Yağı Distile 10ml",
    description: " Kekik yağı, kekikten elde edilen bir bitkisel yağdır. Kekik, daha çok ormanlarda, tarlalarda ve çimenlik alanlarda yetişen bir bitki türüdür. Sıcak iklimlerde yetişen bir türdür. Genellikle mutfak uygulamalarında, baharat olarak kullanılmaktadır. Bilimsel adı ile Thymus Vulgaris Leaf Oil, ağız bakımında, aromaterapide ve kozmetik ürünlerde kullanılmaktadır. Kendine özgü kokusunun yanı sıra, zengin içeriği ile en çok tercih edilen bitkisel yağlardan biridir. Destek Kekik Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/67.jpg",
  },
  {
    id: 68,
    name: "Karanfil Yağı Distile 20ml",
    description: " Karanfil yağı, karanfilden distilasyon yöntemi ile elde edilen bir bitkisel yağdır. Karanfil, mersingiller ailesine özgü bir türdür. Genellikle, Doğu Afrika ve Asya bölgelerinde yetişmektedir. Yağmurlu ve sıcak iklimlere uygun bir türdür. Ülkemizde de bol miktar karanfil yetişmektedir. Tıbbi ve kozmetik kullanımları bulunmaktadır. Ayrıca ağız bakımı için de tercih edilmektedir. Destek Karanfil Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/68.jpg",
  },
  {
    id: 69,
    name: "Karabiber Yağı Soğuk Pres 20ml",
    description: " Karabiber yağı, karabiber baharatından elden edilen bir bitkisel yağdır. Karabiber, günümüzde en çok tüketilen baharatlardan biridir. Karabiber, piper nigrum bitkisinin kurutularak elde edildiği bir üründür. Çoğunlukla mutfak uygulamalarında kullanılan karabiber, içeriğinde yüksek miktarda fitokimyasal bulundurur. Sağlık alanında ve kozmetik sektöründe kullanımı mevcuttur. Destek Karabiber Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/69.jpg",
  },
  {
    id: 70,
    name: "Kabak Çekirdeği Yağı Soğuk Pres 20ml",
    description: " Kabak çekirdeği yağı, kabak çekirdeğinden soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Ülkemizde bolca tüketilen kabak çekirdeği, besin değerleri yüksek ve uzmanlar tarafından önerilen bir besin türüdür. İçeriğindeki vitamin ve mineraller sayesinde, kabak çekirdeği yağı sağlık ve kozmetik sektöründe etkili olarak kullanılır. Destek Kabak Çekirdeği Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/70.jpg",
  },
  {
    id: 71,
    name: "Isırgan Tohumu Yağı Soğuk Pres 20ml",
    description: " Isırgan tohumu yağı, ısırgan otunun tohumlarından elde edilen bir bitkisel yağdır. Bu tohumların soğuk preslenmesi ile yağ elde edilmektedir. Isırgan tohumu, ısırgan otunun en faydalı yerlerinden biridir. Isırgan otu ısırgangiller ailesinin bir türüdür. Yaz aylarından çiçeklenmeye başlar. Bilinen diğer adı ile gençlik yağı, içeriğindeki bileşenler ve organik asitler ile sağlık ve kozmetik alanında sıkça kullanılır. Özellikle cilt ve saç bakımında tercih edilmektedir. Destek Isırgan Tohumu Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/71.jpg",
  },
  {
    id: 72,
    name: "Gül Yağı 20ml",
    description: " Gül yağı, gül bitkisinin yapraklarından elde edilen bir bitkisel yağdır. Yüzyıllardır var olan bu bitkinin 4 ton yaprağından, yaklaşık 1 kilo gül yağı elde edilebilmektedir. Gül, hoş kokusu ve dokusu sayesinde günlük yaşamın her alanında kullanılır. Kokusu sayesinde sabun, şampuan ve parfümlerin içeriğinde bulunabilmektedir. Türkiye, gül üretimi konusunda uluslarası alanda ileri gelen ülkelerden biridir. Ülkemizde Isparta’da yetiştirilen bu güzel bitki, kozmetik alanında yaygın olarak tercih edilmektedir. ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/72.jpg",
  },
  {
    id: 73,
    name: "Greyfurt Yağı Distile 20ml",
    description: " Greyfurt yağı, greyfurt meyvesinden distilasyon yöntemi ile elde edilen bir bitkisel yağdır. İçeriğinde bol miktarda C vitamini bulunduran greyfurt, aynı zamand A ve B vitamini, kalsiyum, potasyum ve magnezyum bakımından da zengindir. Destek Greyfurt Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/73.jpg",
  },
  {
    id: 74,
    name: "Devekuşu Yağı 20ml",
    description: " Deve kuşu yağı, içeriğinde Omega-6, Omega-9 ve çeşitli vitaminleri bulundurur. Emu oil olarak da bilinen bu bitkisel yağ, Eski Mısır, Roma ve Afrika kültürlerinde yıllar boyu kullanılmıştır. Güzellik ve sağlık alanlarında tüketilen deve kuşu yağı, yağ asitleri bakımından da oldukça zengindir. Destek Deve Kuşu Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/74.jpg",
  },
  {
    id: 75,
    name: "Devedikeni Tohumu Yağı Soğuk Pres 20ml",
    description: " Devedikeni tohumu yağı, yabani bir bitki olan devedikeninin tohumundan elde edilen bir bitkisel yağdır. Devedikeni bitkisi, Akdeniz bölgesinde yetişen, papatyagiller familyasına ait bir türdür. Pek çok kullanım alanı olan bu bitki, içeriğinde çeşitli mineraller ve vitaminler bulundurur. Destek Devedikeni Tohumu Yağı, %100 saf ve yerli üretimdir.",
    description2:" Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/75.jpg",
  },
  {
    id: 76,
    name: " Çay Ağacı Yağı Distile 20ml",
    description: " Çay ağacı yağı, hint defnesi adlı bitkiden elde edilen bir bitkisel yağdır. Diğer adıyla Melaleuca Alternifolia Leaf, yassı yapraklı bir bitkidir. Bitki mersingiller cinsine aittir. Avustralya’ya özgü olan bu tür, uzun yıllardır yetiştirilmesine rağmen, son zamanlarda sıkça tüketilmeye başlanmıştır. Cilt bakımında yoğun olarak kullanılmaktadır. Destek Çay Ağacı Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/76.jpg",
  },
  {
    id: 77,
    name: " Buğday Yağı 20ml ",
    description: " Buğday yağı, buğday özünden elde edilen bir bitkisel yağdır. Destek Biberiye Yağı, %100 saf ve yerli üretimdir. Bilimsel adı ile Triticum Vulgare (Wheat) Germ oil, içeriğinde B, D ve E vitaminlerini bulundurur. Aynı zamanda, keratin proteini ile güzellik alanında da kullanılmasını sağlar. Keratin özellikle saç bakımı için kullanılan bir bileşendir. Buğday yağı, içeriğindeki bu bileşen sayesinde kozmetik alanında tercih edilmektedir. Destek Buğday Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/77.jpg",
  },
  {
    id: 78,
    name: " Biberiye Yağı Distile 20ml",
    description: "  Biberiye yağı, biberiye bitkisinin yapraklarından elde edilen bir bitkisel yağdır. Genellikle Akdeniz ikliminde yetişmektedir. Bu yağın geçmişi, Antik Çağ’a kadar uzanmaktadır. Kozmetik ve sağlık alanlarında yaygın kullanımı mevcuttur. Destek Biberiye Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/78.jpg",
  },
  {
    id: 79,
    name: " Bergamot Yağı Distile 20ml ",
    description: "  Bergamot yağı, bergamot bitkisinin distile edilmesiyle elde edilen bir bitkisel yağdır. Bergamot, turunçgiller grubunun bir türüdür. Bilimsel adı Citrus Aurantium Bergamia olan bu bitki, çok sayıda mineral ve vitamin içerir. Genellikle İtalya’nın güney kesiminde yetiştirilen bu bitki, hoş bir kokuya sahiptir. Aroması sayesinde kozmetik alanında ve çay üretimininde de sıkça kullanılmaktadır. Destek Bergamot Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/79.jpg",
  },
  {
    id: 80,
    name: "Aynısefa (Calendula) Yağı 20ml",
    description: "  Aynısefa (Calendula) yağı, aynısefa bitkisinden elde edilen bir bitkisel yağdır. Aynısefa, Akdeniz bölgesine özgü bir bitkidir. Genellikle sarı veya turuncu renktedir ve aromatik bir bitkidir. Bilinen diğer adıyla Calendula bitkisi, tohumlarında yüksek miktarda calendic asit içeren yağları bulundurur. Cilt bakımı ve saç bakımında kullanımı tercih edilir. Destek Aynısefa Yağı, %100 saf ve yerli üretimdir.",
    description2:"Meserasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/80.jpg",
  },
  {
    id: 81,
    name: "Avakado Yağı 20ml",
    description: "  Avokado yağı, avocado meyvesinden elde edilen bir bitkisel yağdır. Avokado, sıcak iklim bölgelerinde yetişen bir tropical meyvedir. Ülkemizde çok yaygın olmamakla birlikte, Akdeniz bölgesinde yetişebilmektedir. Avokado Yağı, içeriğinde birçok minerali ve A, C vitaminlerini bulundurur. Yemek yapımında kullanılabildiği gibi, cilt bakımı ve sağlık alanında da kullanılmaktadır. Destek Avokado Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/81.jpg",
  },
  {
    id: 82,
    name: "At Kestanesi Yağı 20ml",
    description: " At kestanesi yağı, at kestanesi bitkisinden elde edilen bir bitkisel yağdır. Latince adı Aesculus Hippocastanum olan at kestanesi, Balkan yarımadasında yetişen bir bitkidir. Sapindaceae familyasına ait bir türdür. At kestanesi yağı, sağlık ve kozmetik alanında yaygın olarak kullanılan bir bitkisel yağdır. Destek At Kestanesi Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Maserasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/82.jpg",
  },
  {
    id: 83,
    name: "Argan Yağı Soğuk Pres 20ml",
    description: " : Argan yağı, Fas’a özgü bir tür olan Argania Spinosa adlı ağacın çekirdeklerinden elde edilmesiyle oluşan bir bitkisel yağdır. Oleik ve linoleik yağ asitlerini içerir. İçeriğindeki vitaminler ve diğer bileşenler ile özellikle cilt bakımı ve saç bakımında yoğun olarak kullanılır. Argan yağı, çoğu kozmetik ürününün içeriğinde de bulunmaktadır. Destek Argan Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/83.jpg",
  },
  {
    id: 84,
    name: "Ardıç Yağı Distile 20ml",
    description: " Ardıç yağı, ardıç meyvesinden distilasyon yöntemi ile elde edilen bir bitkisel yağdır. Ağaç ve çalı formunda yetiştirilen Juniperus Communis bitkisi, ülkemizde de bazı bölgelerde bulunmaktadır. Başta cilt sağlığı olmak üzere, birçok alanda tüketimi gerçekleşmektedir. Destek Ardıç Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/84.jpg",
  },
  {
    id: 85,
    name: "Anason Yağı Soğuk Pres 20ml",
    description: " Anason yağı, anason tohumundan soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Diğer adı ile, Oleum Pimpinella Anisum, Asya kökenli olmasına ragmen, Akdeniz ülkelerinde de yetiştirilmektedir. Bu yağ, renksiz ve güçlü bir kokuya sahiptir. Destek Anason Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/85.jpg",
  },
  {
    id: 86,
    name: "Aloe Vera Yağı 20ml",
    description: " Aloe vera yağı, aloe vera bitkisinden maserasyon yöntemi ile elde edilen bir bitkisel yağdır. Aloe vera bitkisi, sıcak ve kuru iklimlerde yetişen bir bitkidir ve pek çok türü bulunmaktadır. Genellikle Afrika kıtasında bulunur ve zambakgiller familyasından olup kaktüse benzer bir görünümdedir. İçeriği “Aloe Barbadensis Extract” olarak tanımlanır ve içeriğindeki bileşen ve besin maddelerinin ekstrakt edilmesi, ardından taşıyıcı bir yağda bekletilmesiyle elde edilir. Kozmetik alanında da yaygın kullanımı mevcuttur. Destek Aloe Vera Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/86.jpg",
  },
  {
    id: 87,
    name: "Adaçayı Yağı 20ml",
    description: "  Adaçayı yağı, adaçayı bitkisinden distilasyon yöntemiyle elde edilen bir bitkisel yağdır. Salvia officinalis bitkisinden elde edilen bu yağ, aromatik yapısı ve geniş kullanım özellikleriyle sık kullanılan bir yağdır. Ülkemizde acı elma yağı olarak da bilinmekte ve elde edildiği bitkisi Ege bölgesinde yetiştirilmektedir. Destek Adaçayı Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/87.jpg",
  },
  {
    id: 88,
    name: "Adaçayı Yağı 10ml",
    description: " Adaçayı yağı, adaçayı bitkisinden distilasyon yöntemiyle elde edilen bir bitkisel yağdır. Salvia officinalis bitkisinden elde edilen bu yağ, aromatik yapısı ve geniş kullanım özellikleriyle sık kullanılan bir yağdır. Ülkemizde acı elma yağı olarak da bilinmekte ve elde edildiği bitkisi Ege bölgesinde yetiştirilmektedir. Destek Adaçayı Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/88.jpg",
  },
  {
    id: 89,
    name: "Acıbadem Yağı Soğuk Pres 20ml",
    description: " Acıbadem yağı, bademden soğuk sıkım yöntemiyle elde edilen bir bitkisel yağdır. Badem, doğada tatlı veya acı olmak üzere iki şekilde yetişir. Genellikle cilt bakımında tercih edilmesiyle birlikte; sağlık alanında birçok kullanımı bulunmaktadır. Destek Acıbadem Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/89.jpg",
  },
  {
    id: 90,
    name: "Kabak Çekirdeği Yağı Soğuk Pres 50ml",
    description: " Kabak çekirdeği yağı, kabak çekirdeğinden soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Ülkemizde bolca tüketilen kabak çekirdeği, besin değerleri yüksek ve uzmanlar tarafından önerilen bir besin türüdür. İçeriğindeki vitamin ve mineraller sayesinde, kabak çekirdeği yağı sağlık ve kozmetik sektöründe etkili olarak kullanılır. Destek Kabak Çekirdeği Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/90.jpg",
  },
  {
    id: 91,
    name: "Kabak Çekirdeği Yağı Soğuk Pres 250ml	",
    description: " Kabak çekirdeği yağı, kabak çekirdeğinden soğuk sıkım yöntemi ile elde edilen bir bitkisel yağdır. Ülkemizde bolca tüketilen kabak çekirdeği, besin değerleri yüksek ve uzmanlar tarafından önerilen bir besin türüdür. İçeriğindeki vitamin ve mineraller sayesinde, kabak çekirdeği yağı sağlık ve kozmetik sektöründe etkili olarak kullanılır. Destek Kabak Çekirdeği Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/91.jpg",
  },
  {
    id: 92,
    name: "Tarçın Yağı Distile 20ml",
    description: " Tarçın yağı, “Laurus cinnamomum” adlı bitkiden distilasyon yöntemi ile elde edilmektedir. Tarçın bitkileri Güney Asya’nın bazı bölgelerine özgüdür. Oldukça etkili olan bu bitkisel yağ, içeriğinde aldehit ve öjenol gibi maddeleri barındırır. Hoş kokusu ile kozmetik alanında yaygın olarak kullanılır. Destek Tarçın Yağı, %100 saf ve yerli üretimdir.",
    description2:" Distilasyon",
    description3:" Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/92.jpg",
  },
  {
    id: 93,
    name: "	Mavi Anemon Yağı Distile 20ml",
    description: "  Mavi anemon yağı, mavi anemon çiçeğinden distilasyon yöntemi ile elde edilen bir bitkisel yağdır. Mavi anemon çiçeği ılıman iklimlerde yetişir. Ülkemizde, Ege ve Akdeniz bölgelerinde doğal olarak bulunur. Günümüzde, cilt sağlığına etkileri ve kokusu nedeniyle kozmetik alanında yaygın olarak kullanılır. Destek Mavi Anemon Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Distilasyon",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/93.jpg",
  },
  {
    id: 94,
    name: "Kayısı Çekirdeği Yağı Soğuk Pres  20ml",
    description: " Kayısı çekirdeği yağı, kayısı ağacından toplanan meyvelerin kurutulmuş çekirdeklerinden elde edilen bir bitkisel yağdır. Besin değeri oldukça yüksek bir meyve olan kayısı, içeriğinde A, C ve E vitaminlerini bulundurur. Son yıllarda cilt bakımı için kullanılmaya başlanan kayısı çekirdeği yağı, aynı zamanda aromaterapi için de tercih edilmektedir. Hafif ve kokusuz bir yağ olan kayısı çekirdeği yağı, soğuk sıkım yöntemiyle elde edilmektedir. Destek Kayısı Çekirdeği Yağı, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/94.jpg",
  },
  {
    id:95,
    name: "Nar Çiçeği Yağı Soğuk Pres 20ml",
    description: " Nar çekirdeği yağı, narın çekirdeğinden soğuk sıkım yöntemiyle elde edilen bir bitkisel yağdır. Nar, Orta Doğu ve Asya’ya özgü, koyu yeşil yapraklı, kırmızı görünümlü bir meyvedir. Ülkemizde de yetişebilen bir meyve olmasıyla birlikte, içeriğinde C, K vitaminleri ve potasyum, demir bulundurmaktadır. Destek Nar Çekirdeği Yağı, %100 saf ve yerli üretimdir.    ",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/95.jpg",
  },
  {
    id:96,
    name: "  Gliserin 50ml",
    description: " Gliserin, hidrojen, oksijen ve karbondan meydana gelen bir bileşiktir. Renksiz ve tatlı bir sıvıdır. Diğer adı ile gliserol, hayvan ve bitki yağlarından elde edilmektedir. Cilt ve saç bakımı için kullanılmaktadır. Destek Gliserin, %100 saf ve yerli üretimdir.",
    description2:"Soğuk Sıkım (Cold Press)",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    number: "1",
    category: "100% SAF BİTKİ YAĞLARI",
    url: "img/portfolio/96.jpg",
  },
 
  


// ÖZELL KARIŞIM YAĞLAR----------ÖZELL KARIŞIM YAĞLAR--------------ÖZELL KARIŞIM YAĞLAR-------------ÖZELL KARIŞIM YAĞLAR


  {
    id: 97,
    name: "Kaş ve Kirpik Bakım Yağı 20ml",
    description: "  Destek Kaş & Kirpik Bakım Yağı ile kaş ve kirpiklerinize canlı bir görünüm kazandırın! Keratin ve doğal yağlar ile güçlendirilmiş formülü ile kaş ve kirpikleriniz için %100 doğal bakım. ",
    description2:"Ricins Communes Seed Oil (Hint Yağı, Prunus Amygdalus Dulcis Oil (T. Badem Yağı), Sesamum Indicum Seed Oil (Susam Yağı), Corylus Avellana Seed Oil (Fındık Yağı), Triticum Vulgare Germ Oil (Buğday Yağı), Cocos Nucifera Oil (Hindistan Cevizi Yağı), Aloe Barbadensis Leaf (Aloevera Yağı), Butyrospermum Parkii Butter (Shea Yağı), Nigella Sativa Seed Oil (Çörekotu Yağı), Simmondsia Chinensis Seed Oil (Jojoba Yağı), Hydrolyzed Keratin, Urtica Dioica (Isırgan Yağı), Tocopheryl Acetate (Vitamin E), Isopropyl Myristate.",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    
    number: "2",
    category: "ÖZEL KARIŞIM YAĞLAR",
    url: "img/portfolio/97.jpg",
  },

  {
    id: 98,
    name: "Sakal ve Bıyık Bakım Yağı 50ml					",
    description: "  Destek Sakal & Bıyık Bakım Yağı, içeriğindeki bitki yağları ve E vitamini ile sakal ve bıyıkları beslemeye yardımcı olur.Erkekler için özel olarak geliştirilmiş %100 doğal içerikli formül!  ",
    description2:"E-Vitamini Nedir? E-Vitamini yağda çözülebilen vitamin gruplarından bir tanesidir. Cilt için en önemli vitaminlerin başında gelen E-vitamini, güçlü bir antioksidandır. Bilinen diğer faydaları ise; yaraların çabuk iyileşmesini sağlaması, cildin parlak bir görünüm kazanması, cildin nem dengesini desteklemesi ve saç sağlığına yardımcı olmasıdır. Sakal ve bıyık gelişimini ve güçlenmesini desteklemektedir. ",
    description3:"Kullanmadan önce şişeyi hafifçe çalkalayınız. Her gün bir kez uygulayınız. Ürünün etkisini gösterebilmesi için, sakal ve bıyık traşı sonrası uygulamanız tavsiye edilir. Temiz cilde hafifçe masaj yaparak yediriniz. Düzenli kullanılması önerilir. Kullanım sonrası yüzünüzü yıkamayınız. Sakal ve bıyık bölgeleriniz dışında uygulamayınız.",
    
    number: "2",
    category: "ÖZEL KARIŞIM YAĞLAR",
    url: "img/portfolio/98.jpg",
  },

  {
    id: 99,
    name: "Aromaterapi Masaj Yağı Egzotik 150ml",
    description: "  Destek Aromaterapi Masaj Yağı, egzotik ve hoş kokulu bitki yağlarından oluşan, aromaterapi amaçlı kullanılabilen doğal bir masaj yağıdır. Aromaterapi masajı, çeşitli bitki yağları ile hazırlanan karışımın kullanıldığı, rahatlatıcı ve dinlendirici bir masaj türüdür. Egzotik masaj yağı, hoş aroması ile yoğun ağrılar üzerinde etkili olur ve canlandırıcı bir etki sağlar.     ",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",

    number: "2",
    category: "ÖZEL KARIŞIM YAĞLAR",
    url: "img/portfolio/99.jpg",
  },

  {
    id: 100,
    name: "Snoil Doğal Bitki Yağı 20ml",
    description: "  Destek Snoil Doğal Bitki Yağı, 15 çeşit bitkisel yağ içeriği ile %100 saf ve yerli üretimdir. ",
    description2:"İçindekiler: Nigella Sativa Seed Oil (Çörekotu Yağı), Corylus Avellana Seed Oil (Fındık Yağı), Hypericum Perforatum Oil (Kantaron Yağı), Petroselinum Sativum Extract (Maydanoz Tohumu Yağı), Chamomilla Recutita Flower Oil (Papatya Yağı), Calendula Officinalis Flower Oil (Aynısefa Çiçeği Yağı),Mentha Piperita Oil (Nane Yağı), Plantago Major Leaf Extract (Sinirli Ot Yağı), Urtica Dioica (Isırgan Tohumu Yağı), Eucalyptus Globulus Leaf Oil (Okaliptüs Yağı), Eugenia Caryophyllus Flower Oil (Karanfil Yağı), Glycerin, Thymus Vulgaris Leaf Oil (Kekik Yağı), Allium Sativum Bulb Oil (Sarımsak Yağı), Juniperus Communis Fruit Oil (Ardıç Yağı), Lavandula Angustifolia Oil (Lavanta Yağı)Ricins Communes Seed Oil (Hint Yağı, Prunus Amygdalus Dulcis Oil (T. Badem Yağı), Sesamum Indicum Seed Oil (Susam Yağı), Corylus Avellana Seed Oil (Fındık Yağı), Triticum Vulgare Germ Oil (Buğday Yağı), Cocos Nucifera Oil (Hindistan Cevizi Yağı), Aloe Barbadensis Leaf (Aloevera Yağı), Butyrospermum Parkii Butter (Shea Yağı), Nigella Sativa Seed Oil (Çörekotu Yağı), Simmondsia Chinensis Seed Oil (Jojoba Yağı), Hydrolyzed Keratin, Urtica Dioica (Isırgan Yağı), Tocopheryl Acetate (Vitamin E), Isopropyl Myristate.",
   
    
    number: "2",
    category: "ÖZEL KARIŞIM YAĞLAR",
    url: "img/portfolio/100.jpg",
  },

  {
    id: 101,
    name: "Epilasyon Sonrası Bakım Yağı 150ml ",
    description: "  Destek Epilasyon Bakım Yağı, cildi kolayca temizler. Ciltteki hassasiyeti gidererek diri bir görünüme kavuşmasını sağlar. Epilasyon, ağda sonrası kullanıma uygundur. İyi sonuç almak için uygulamadan hemen sonra kullanılmalıdır. ",
    description2:"Paraffinum Liquidum, Arnica Montana Flower Oil, Calendula Officials Flower Oil, Tocopheryl Acetate, Parfüm.",
    description3:"Kullanım Şekli: Epilasyondan sonra elle masaj yaparak sürünüz.. Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    
    number: "2",
    category: "ÖZEL KARIŞIM YAĞLAR",
    url: "img/portfolio/101.jpg",
  },
  {
    id: 102,
    name: "Doğal Vücut Bakım Yağı 150ml ",
    description: " Strechmax Doğal Vücut Bakım Yağı, 15 çeşit bitkisel yağ içeriği ile %100 saf ve yerli üretimdir. İçeriğindeki E vitamini ve bakım yağları ile cildin yoğun bir şekilde beslenmesine yardımcı olur. ",
    description2:"Nigella Sativa Seed Oil (Çörekotu Yağı), Corylus Avellana Seed Oil (Fındık Yağı), Hypericum Perforatum Oil (Kantaron Yağı), Petroselinum Sativum Extract (Maydanoz Tohumu Yağı), Chamomilla Recutita Flower Oil (Papatya Yağı), Calendula Officinalis Flower Oil (Aynısefa Çiçeği Yağı),Mentha Piperita Oil (Nane Yağı), Plantago Major Leaf Extract (Sinirli Ot Yağı), Urtica Dioica (Isırgan Tohumu Yağı), Eucalyptus Globulus Leaf Oil (Okaliptüs Yağı), Eugenia Caryophyllus Flower Oil (Karanfil Yağı), Glycerin, Thymus Vulgaris Leaf Oil (Kekik Yağı), Allium Sativum Bulb Oil (Sarımsak Yağı), Juniperus Communis Fruit Oil (Ardıç Yağı), Lavandula Angustifolia Oil (Lavanta Yağı)",
    
    number: "2",
    category: "ÖZEL KARIŞIM YAĞLAR",
    url: "img/portfolio/102.jpg",
  },
  {
    id: 103,
    name: "Doğal Masaj Yağı 150ml	 ",
    description: "  Destek Kaş & Kirpik Bakım Yağı ile kaş ve kirpiklerinize canlı bir görünüm kazandırın! Keratin ve doğal yağlar ile güçlendirilmiş formülü ile kaş ve kirpikleriniz için %100 doğal bakım. ",
    description2:"Aromaterapi masajı, çeşitli bitki yağları ile hazırlanan karışımın kullanıldığı, rahatlatıcı ve dinlendirici bir masaj türüdür. Relax masaj yağı, ferahlatıcı aroması ile yoğun ağrılar üzerinde etkili olur ve rahatlatıcı bir etki sağlar.",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    
    number: "2",
    category: "ÖZEL KARIŞIM YAĞLAR",
    url: "img/portfolio/103.jpg",
  },
  {
    id: 104,
    name: "Aromaterapi Masaj Yağı Relax 150ml	",
    description: " Destek Aromaterapi Masaj Yağı, ferah ve hoş kokulu bitki yağlarından oluşan, aromaterapi amaçlı kullanılabilen doğal bir masaj yağıdır. ",
    description2:"Ricins Communes Seed Oil (Hint Yağı, Prunus Amygdalus Dulcis Oil (T. Badem Yağı), Sesamum Indicum Seed Oil (Susam Yağı), Corylus Avellana Seed Oil (Fındık Yağı), Triticum Vulgare Germ Oil (Buğday Yağı), Cocos Nucifera Oil (Hindistan Cevizi Yağı), Aloe Barbadensis Leaf (Aloevera Yağı), Butyrospermum Parkii Butter (Shea Yağı), Nigella Sativa Seed Oil (Çörekotu Yağı), Simmondsia Chinensis Seed Oil (Jojoba Yağı), Hydrolyzed Keratin, Urtica Dioica (Isırgan Yağı), Tocopheryl Acetate (Vitamin E), Isopropyl Myristate.",
    description3:"Kapağı kapalı olarak, serin ve kuru ortamlarda muhafaza edin. Güneş ışığı ve ısıdan uzak tutun. Çocuklardan uzak yerlerde muhafaza edin.",
    
    number: "2",
    category: "ÖZEL KARIŞIM YAĞLAR",
    url: "img/portfolio/104.jpg",
  },



      // Cilt Bakımı        Cilt Bakımı         Cilt Bakımı         Cilt Bakımı         Cilt Bakımı






  {
    id: 105,
    name: "Likit Peeling Jel 150ml (Kırmızı Yosun Özlü)	",
    description: "  Destek Peel-Up Likit Peeling, cildin üzerindeki kiri ve ölü deriyi yüzeyden kaldırarak ürünün içine hapseder. Jelden peelinge dönüşen dokusu ile ciltteki kirlerin, ölü deri ile birlikte alınıp soyulmasını sağlar. Arınmış, taze ve temiz cildi açığa çıkarır. İskandinav sularında yaygın olarak bulunan Furcellaria lumbricalis kırmızı yosunu ve doğal bitki yağları içeren Destek Peel-Up Likit Peeling, cildin arınmasını sağlar ve daha pürüzsüz ve aydınlık bir görünüme kavuşmasına yardımcı olur. Alkol, paraben ve esans içermez. GMP koşullarında üretilmektedir.",
    description2:"Aqua, Proplene Glycol, Carbomer, Glycerin, Quaternium-18, Vitis Vinifera Seed Oil (Üzüm Çekirdeği Yağı), Behentrimonium Chloride, Persea Gratissima Oil (Avokado Yağı), Sodium Carrageenan, Phenoxyethanol, Jasminum Officinale Oil (Yasemin Yağı), Ethylhexylglycerin, Phenethyl Alcohol, Sea salt",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/105.jpg",
  },
  {
    id: 106,
    name: "Kollajen Krem 50ml (Kırmızı Yosun Özlü)	",
    description: "  Destek Yaşlanma Karşıtı Kollajen Krem, içeriğinde kollajen, shea yağı, sodyum PCA, kırmızı yosun ekstraktı ve 8 çeşit aminoasit bulunan güçlü formülü ile cildin daha homojen bir görünüm kazanmasına yardımcı olur. Olgun ciltlerin bakımı için idealdir. Alkol ve paraben içermez. Dermatolojik olarak test edilmiştir. GMP koşullarında üretilmektedir.",
    description2:"Aqua, C12-20 Acid PEG-8 Ester, Neopentyl Glycol Diheptanoate, Butyrospermum Parkii Butter, Glyceryl Stearate, Peg-100 Stearate, Hydrolyzed Collagen, Sodium PCA, Sodium Lactate, Arginine, Aspartic Acid, PCA, Glycine, Alanine, Serine, Valine, Proline, Threonine, Isoleucine, Histidine, Phenylalanine, Sodium Carrageenan, Sea Salt, Phenethyl Alcohol, Phenoxyethanol, Ethylhexylglycerin, Parfum, Butylphenyl Methylpropional, Benzyl Salicylate, Coumarin, Geraniol.",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/106.jpg",
  },
  {
    id: 107,
    name: "Acıbadem Sütü 75ml",
    description: "  Destek Acıbadem Sütü, cildinizi temizleme ve besleme özelliğine sahip bir makyaj temizleme ve bakım ürünüdür. İçeriğindeki doğal acıbadem yağı ile cildinize canlılık ve tazelik hissi verir.     ",
    description2:" Aqua, Cyclopentasiloxane, Ethylhexyl Palmitate, Hydrogenated Polydecene, Glyceryl Stearate, Prunus Amygdalus Amara Kernel Cold Pressed Oil, Dimethicone, Glycerin, Cetearyl Alcohol, Sesamum Indicum Seed Oil, Stearic Acid, Propylene Glycol, Peg-100 Stearate, Phenoxyethanol, Ethylhexylglycerin, Tocopheryl Acetate, Carbomer, Parfüm.",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/107.jpg",
  },
  {
    id: 108,
    name: "Ayak Bakım Spreyi 50ml			",
    description: "  Destek M-Stop Ayak Bakım Spreyi, Sinirli Ot Öz Suyu ve Çay Ağacı Yağı içerikli bir ayak bakım spreyidir. Bu doğal içerikli sprey; hoş kokulu, yumuşak ve bakımlı ayaklara sahip olmanızı sağlar.    ",
    description2:"Alcohol, Aqua, Propylene Glycol, Plantago Major Leaf Extract (Sinirli Ot Ekstresi), Glycerin, Melaleuce Alternifolia Leaf Oil (Çay Ağacı Yağı), Piroctone Olamine, Parfüm, Hexyl Cinnamal, Butylphenyl Methylpropional, Limonene, Hydroxycitronellal, Geraniol, Citronellol.",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/108.jpg",
  },
  {
    id: 109,
    name: "Salyangoz Salgı Özlü Cilt Maskesi 140g	",
    description: "  cildin üzerindeki kiri ve ölü deriyi yüzeyden kaldırarak ürünün içine hapseder. Jelden peelinge dönüşen dokusu ile ciltteki kirlerin, ölü deri ile birlikte alınıp soyulmasını sağlar. Arınmış, taze ve temiz cildi açığa çıkarır. İskandinav sularında yaygın olarak bulunan Furcellaria lumbricalis kırmızı yosunu ve doğal bitki yağları içeren Destek Peel-Up Likit Peeling, cildin arınmasını sağlar ve daha pürüzsüz ve aydınlık bir görünüme kavuşmasına yardımcı olur. Alkol, paraben ve esans içermez. GMP koşullarında üretilmektedir.",
    description2:"Aqua, Proplene Glycol, Carbomer, Glycerin, Quaternium-18, Vitis Vinifera Seed Oil (Üzüm Çekirdeği Yağı), Behentrimonium Chloride, Persea Gratissima Oil (Avokado Yağı), Sodium Carrageenan, Phenoxyethanol, Jasminum Officinale Oil (Yasemin Yağı), Ethylhexylglycerin, Phenethyl Alcohol, Sea salt",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/109.jpg",
  },

  {
    id: 110,
    name: "Kayısı Özlü Krem 45g		",
    description: "  Destek Kayısı Özlü Krem, kayısı yağı ve provitamin B5 içeriği ile cilt bakımına yardımcı olur. Besin değeri oldukça yüksek bir meyve olan kayısı, içeriğinde A, C ve E vitaminlerini bulundurur.    ",
    description2:" Aqua, cyclopentasiloxane, ethylhexyl palmitate, glyceryl stearate, peg-100 stearate, prunus armeniaca kernel oil, hydrogenated polydecene, dimethicone, glycerin, cetearly alcohol, steraic acid, panthenol, propylene glycol, triticum vulgare germ oil, tocopheryl acetate, phenoxyethanol, ethylhexylglycerin, carbomer, triethanolamine, parfüm, buthylphenyl methylpropional, hexyl cinnamal, benzyl salicylate, citral, citronellol, limonene, hydroxycitronellal, linalool, hydroxyisohexyl 3-cyclohexene carboxaldehyde",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/110.jpg",
  },
  {
    id: 111,
    name: "24' lü Tek Kullanımlık Cilt Maskesi (Tüm Ciltler İçin)	",
    description: "  Doğal kil maskesidir. 24 adet tek kullanımlık maskelerden oluşur. Cilt bakımına yardımcı olur. Tüm cilt tipleri için uygundur.",
    description2:"Aqua, Proplene Glycol, Carbomer, Glycerin, Quaternium-18, Vitis Vinifera Seed Oil (Üzüm Çekirdeği Yağı), Behentrimonium Chloride, Persea Gratissima Oil (Avokado Yağı), Sodium Carrageenan, Phenoxyethanol, Jasminum Officinale Oil (Yasemin Yağı), Ethylhexylglycerin, Phenethyl Alcohol, Sea salt",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/111.jpg",
  },
  {
    id: 112,
    name: "At Kestanesi Jeli 500ml	",
    description: "  Destek At Kestanesi Jeli, at kestanesi, aynısefa, üzüm çekirdeği, nane ve jojoba yağları içeren zengin formülüyle, cildin tazelik kazanmasına ve kendinizi daha iyi hissetmenize yardımcı olur.    ",
    description2:"Aqua, Alcohol, Aesculus Hippocastanum Extract (At Kestanesi), Propylene Glycol, Carbomer, Menthol, Camphor, Calendula Officinalis Flower Oil (Aynısefa), Vitis Vinifera Seed Oil (Üzüm Çekirdeği), Mentha Piperita Oil (Nane), Eucalyptus Globulus Leaf Oil (Okaliptüs), Simmondsia Chinensis Seed Oil (Jojoba), Triticium Vulgare Germ Oil, (Buğday), Arnica Montana Flower Oil (Arnika), Triethanolamine, Phenoxyethanol, Ethylhexylglycerin, Cl 19140, Cl 42090.    ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/112.jpg",
  },
  {
    id: 113,
    name: "Estomin Arnica Jel 100ml",
    description: "  Ingredients: Aqua, Petroselinum Crispum Leaf Extract, Phenoxyethanol, Carbomer, Triethanolamin, Arnica Montana Flower Extract, Centella Asiatica Leaf Extract, Olea Europaea Fruit Oil, Ethylhexylglycerin, Menaquinone-7.",
    description2:"Aqua, Proplene Glycol, Carbomer, Glycerin, Quaternium-18, Vitis Vinifera Seed Oil (Üzüm Çekirdeği Yağı), Behentrimonium Chloride, Persea Gratissima Oil (Avokado Yağı), Sodium Carrageenan, Phenoxyethanol, Jasminum Officinale Oil (Yasemin Yağı), Ethylhexylglycerin, Phenethyl Alcohol, Sea salt",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/113.jpg",
  },
  {
    id: 114,
    name: "Estomin-C Aynısefa Kremi 50ml	",
    description: "  Uygulanacak bölgeye, her gün en az 3 defa olmak üzere, kalın bir tabaka halinde sürünüz.",
    description2:"Ingredients: Petrolatum (Vazelin), Calendula Officinalis Flower (Aynısefa Çiçeği), Paraffinum Liquidum, Viola Odorata Oil (Menekşe Yağı), Hypericum Perforatum Flower Extract (Kantaron Otu), Calendula Officinalis Flower Oil(Aynısefa Yağı), Citrus Aurantium Dulcis Peel Oil (Portakal Yağı),Plantago Major Leaf Extract (Sinirli Ot Yağı), Hypericum Perforatum Oil (Kantaron Yağı), Alchemilla Vulgaris Leaf Extract (Aslanpençesi), Symphytum Officinale Leaf Extract (Karakafes Otu).    ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/114.jpg",
  },

  {
    id: 115,
    name: "HC At Kestanesi Kremi 50ml	",
    description: "  Ürünü ilk defa kullanıyorsanız, kolunuzun üst kısmına bir miktar sürüp 10 dakika bekleyiniz. Kaşıntı, kızarıklık veya alerjik bir durumla karşılaşırsanız kullanmayınız.Göze temas ettirmeyiniz. Haricen kullanılır. Çocukların erişemeyeceği ve serin bir ortamda ağzı kapalı olarak muhafaza ediniz.",
    description2:"Aqua, Cyclopentasiloxane, Ethylexyl Palmitate, Glyceryl Stearate, Aesculus Hippocastanum Extract (At Kestanesi Yağı), Calendula Officinalis Flower Oil (Aynısefa Çiçeği Yağı), Hydrogenated Polydecene, Peg-100 Stearate, Vitis Vinifera Seed Oil (Üzüm Çekirdeği Yağı), Cetearly Alcohol, Chamomilla Recutita Flower Oil (Papatya Yağı), Citrus Aurantium Dulcis Peel Oil (Portakal Yağı), Dimethicone, Glycerin, Hypericum Perforatum Oil (Kantaron Yağı), Propylene Glycol, Stearic Acid, Urtica Dioica (Isırgan Yağı), Carbomer, Corylus Avellana Seed Oil (Fındık Yağı), Nigella Sativa Seed Oil (Çörekotu Yağı), Rosmarinus Officinals Leaf Oil (Biberiye Yağı), Phenoxyethanol, Ethylhexylglycerin, Triethanolamine.",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/115.jpg",
  },
  {
    id: 116,
    name: "HC At Kestanesi Jel Sprey 275ml	",
    description: "  Gözle temasından kaçınınız, temas halinde bol su ile yıkayınız. Sadece harici kullanım içindir. Gıda maddesi değildir, yutulmaz. Çocukların ulaşamayacağı yerlerde muhafaza ediniz. Güneş ışığı ve ısıdan uzak tutunuz. ",
    description2:"Aqua, Alcohol, Aesculus, Hippocastanum Extract (At Kestanesi), Propylene Glycol, Carbomer, Menthol, Camphor, Calendula Officinalis Flower Oil (Aynısefa), Vitis Vinifera Seed Oil (Üzüm Çekirdeği), Mentha Piperita Oil (Nane), Eucalyptus Globulus Leaf Oil (Okaliptüs), Simmondsia Chinensis Seed Oil (Jojoba), Triticium Vulgare Germ Oil (Buğday) Arnica Montana Flower Oil (Arnika), Triethanolamine, Phenoxyethanol, Ethylhexylglycerin, Cl 19140, Cl 42090.    ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/116.jpg",
  },
  {
    id: 117,
    name: "LDoğal Cilt Maskesi 140g		",
    description: " cildin üzerindeki kiri ve ölü deriyi yüzeyden kaldırarak ürünün içine hapseder. Jelden peelinge dönüşen dokusu ile ciltteki kirlerin, ölü deri ile birlikte alınıp soyulmasını sağlar. Arınmış, taze ve temiz cildi açığa çıkarır. İskandinav sularında yaygın olarak bulunan Furcellaria lumbricalis kırmızı yosunu ve doğal bitki yağları içeren Destek Peel-Up Likit Peeling, cildin arınmasını sağlar ve daha pürüzsüz ve aydınlık bir görünüme kavuşmasına yardımcı olur. Alkol, paraben ve esans içermez. GMP koşullarında üretilmektedir.",
    description2:"Aqua, Proplene Glycol, Carbomer, Glycerin, Quaternium-18, Vitis Vinifera Seed Oil (Üzüm Çekirdeği Yağı), Behentrimonium Chloride, Persea Gratissima Oil (Avokado Yağı), Sodium Carrageenan, Phenoxyethanol, Jasminum Officinale Oil (Yasemin Yağı), Ethylhexylglycerin, Phenethyl Alcohol, Sea salt",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/117.jpg",
  },
  {
    id: 118,
    name: "Keklik Üzümlü Krem Set (İkisi Bir Arada) 185g		",
    description: "  Agromax Keklik Üzümlü Krem, içeriğinde bulunan bitki yağları sayesinde masaj yapılmasını kolaylaştırır. Mentol içerikli hoş kokusuyla ferahlık verir, rahatlama sağlar.  ",
    description2:"Aqua, Proplene Glycol, Carbomer, Glycerin, Quaternium-18, Vitis Vinifera Seed Oil (Üzüm Çekirdeği Yağı), Behentrimonium Chloride, Persea Gratissima Oil (Avokado Yağı), Sodium Carrageenan, Phenoxyethanol, Jasminum Officinale Oil (Yasemin Yağı), Ethylhexylglycerin, Phenethyl Alcohol, Sea salt",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/118.jpg",
  },
  {
    id: 119,
    name: "At Kestanesi Özlü Krem 45g		",
    description: " Destek At Kestanesi Özlü Krem, içeriğinde 10 farklı bitkisel yağı bulunduran, tüm cilt tiplerinde kullanılabilen bir doğal kremdir.   ",
    description2:"Aqua, Glyceryl Stearate & Peg-100 Stearate, Cylopentasiloxane, Ethylhexyl Palmitate, Gaultheria Procumbens Leaf Oil (Keklik Üzümü Yağı), Capsicum Frutescens Fruit Extract (Acıbiber Yağı), Dromiceius Oil (Devekuşu Yağı), Cetearyl Alcohol, Glycerin, Dimethicone, Propylene Glycol, Menthol, Salmo Oil (Alabalık Yağı), Stearic Acid, Tyhmus Vulgaris Oil (Kekik Yağı), Citrus Aurantium Bergamia Leaf Oil (Bergamot Yağı), Lavandula Angustifolia Oil (Lavanta Yağı), Copaifera Reticulata Balsam Oil (Pelesenk Yağı), Camphor (Kafur), Hypericum Perforatum Oil (Kantaron Yağı), Phenoxyethanol (and) Ethylhexylglycerin, Turpentine (Çam Terebentin), Rosmarinus Officinalis Flower Oil (Biberiye Yağı), Eucalyptus Globulus Leaf Oil (Okaliptus Yağı), Triethanolamine, Carbomer.",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/119.jpg",
  },

  {
    id: 120,
    name: "Aynısefa (Calendula) Özlü Krem 30g	",
    description: "   Destek Aynısefa (Calendula) Özlü Krem, içeriğinde aynısefa yağı, portakal yağı, menekşe yağı gibi bitkisel içerikleri bulunduran bir doğal kremdir. Cilt bakımı için kullanılır.     ",
    description2:"Aqua, Glyceryl Stearate & Peg-100 Stearate, Cyclopentasiloxane, Ethylhexyl Palmitate, Aesculus Hippocastanum Extract (At Kestanesi Yağı), Calendula Officinalis Flower Oil (Aynısefa Yağı), Vitis Vinifera Seed Oil (Üzüm Çekirdeği Yağı), Hydrogenated Polydecane, Glycerin, Cetearyl Alcohol, Dimethicone, Urtica Dioica (Isırgan Yağı), Chamomilla Recutita Flower Oil (Papatya Yağı), Citrus Aurantium Dulcis Peel Oil (Portakal Yağı), Hypericum Perforatum Oil (Kantaron Yağı), Propylene Glycol, Stearic Acid, Phenoxyethanol (and) Ethylhexylglycerin, Corylus Avellana Seed Oil (Fındık Yağı), Nigella Sativa Seed Oil (Çörekotu Yağı), Rosmarinus Officinalis Leaf Oil (Biberiye Yağı), Carbomer, Triethanolamine.",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/120.jpg",
  },
  {
    id: 121,
    name: "Kantaron Özlü Krem 45g		",
    description: "  Destek Kantaron Özlü Krem, içeriğinde birçok bitki yağı bulunduran, cilt bakımı ve arometerapi masajı için kullanılabilen bir doğal kremdir. Tüm cilt tiplerinde kullanılabilir. Bitkisel içeriği sayesinde masaj yapılmasını kolaylaştırır.    ",
    description2:"Aqua, Plantago Major Leaf Extract (Sinirli Ot Ekstresi), Petrolatum (Vazelin), Glyceryl Stearate & Peg-100 Stearate, Cyclopentasiloxane, Ethylhexyl Palmitate, Hypericum Perforatum Oil (Kantaron Yağı), Calendula Officinalis Flower Oil (Aynısefa Yağı), Urtica Dioica (Isırgan Tohumu Yağı), Ceteareth-12, Cetearyl Alcohol, Dimethicone, Melaleuca Alternifolia Leaf Oil (Çay Ağacı Yağı), Allium Sativum Bulb Oil (Sarımsak Yağı), Stearic Acid, Glycerin, Propylene Glycol, Phenoxyethanol (and) Ethylhexylglycerin, Allantoin, Piroctone Olamine, Carbomer, Triethanolamine, Parfüm, Hexyl Cinnamal, Butylphenyl Methylpropional, Limonene, Hydroxycitronellal, Geraniol, Citronellol.",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/121.jpg",
  },
  {
    id: 122,
    name: "Salyangoz Salgı Özlü Krem 45g	",
    description: "  Destek Salyangoz Salgı Özlü Krem, içeriğinde bulunan bitki yağları, E vitamini ve allantoin sayesinde cilt bakımına yardımcı olur. Tüm cilt tipleri için uygundur.    ",
    description2:"Aqua, Glyceryl Stearate & Peg-100 Stearate, Cyclopentasiloxane, Ethylhexyl Palmitate, Hydrogeneated Polydecane, Sesamum Indicum Seed Oil (Susam Yağı), Prunus Armeniaca Kernel Oil (Kayısı Çekirdeği Yağı), Plantago Major Leaf Extract (Sinirli Ot Ekstresi), Propylene Glycol, Glycerin, Dimethicone, Allantoin, Cetearyl Alcohol, Stearic Acid, Calendula Officinalis Flower Oil (Aynısefa Yağı), Phenoxyethanol (and) Ethylhexylglycerin, Tocopherol (E Vitamini), Carbomer, Triethanolamine, Parfüm, Butylphenyl Methylpropional, Citronellol, Benzyl Salicylate, Linalool, Hexyl Cinnamal, Geraniol, Alpha-IsomethylIonone, Coumarin, Benzyl Benzoate, Isoeugenol.",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/122.jpg",
  },
  {
    id: 123,
    name: "Gülsuyu 275ml (%100 Doğal)	",
    description: "  Gül yapraklarının damıtılmasıyla elde edilen Destek %100 Doğal Gül Suyu, doğal tonik etkisiyle, cildinizi hassasça temizler ve ferahlık hissi verir. Kokusu ve faydaları sayesinde sıklıkla tercih edilir. Gül suyu, içerisinde A, B3 ve D vitaminlerini bulundurur. Cilt bakımında yoğun kullanım alanları bulunur. Alkol, boya ve katkı maddesi içermez.",
    description2:"Rosa damascena Flower Water (%100 Saf Distile Gül Suyu).",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/123.jpg",
  },
  {
    id: 124,
    name: "At Kestanesi Jeli 275ml			",
    description: "  Destek At Kestanesi Jeli, at kestanesi, aynısefa, üzüm çekirdeği, nane ve jojoba yağları içeren zengin formülüyle, cildin tazelik kazanmasına ve kendinizi daha iyi hissetmenize yardımcı olur.    ",
    description2:"Latince adı Aesculus Hippocastanum olan at kestanesi, Balkan yarımadasında yetişen bir bitkidir. Sapindaceae familyasına ait bir türdür. At kestanesi jeli, sağlık ve kozmetik alanında yaygın olarak kullanılan bir bitkisel üründür. Destek At Kestanesi Jeli, %100 saf ve yerli üretimdir.    ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "CİLT BAKIMI",
    url: "img/portfolio/124.jpg",
  },
  {
    id: 125,
    name: "Doğal Saç Bakım Yağı 150ml			",
    description: "  Hairnice Saç Bakım Yağı, doğal ve zengin içeriği ile saçların beslenmesine yardımcı olur. Keratin ve E vitamini içerir.    ",
    description2:"Prunus Amygdalus Dulcis Oil (Tatlı Badem Yağı), Olea Europaea Fruit Oil (Zeytin Yağı), Helianthus Annuus Seed Oil, Sesamum Indicum Seed Oil (Susam Yağı), Cocos Nucifera Oil (Hindistan Cevizi Yağı), Hydrolyzed Keratin, Ricinus Communis Seed Oil (Hint Yağı), Nigella Sativa Seed Oil (Çörekotu Yağı), Juglans Regia Seed Oil (Ceviz Yağı), Allium Sativum Bulb Oil (Sarımsak Yağı), Vitis Vinifera Seed Oil (Üzüm Çek. Yağı), Triticum Vulgare Germ Oil (Buğday Yağı), Simmondsia Chinensis Seed Oil (Jojoba Yağı), Isopropyl Myristate, Urtica Dioica (Isırgan Yağı), Rosmarinus Officinalis Leaf Oil (Biberiye Yağı), Argania Spinosa Kernel Oil (Argan Yağı), Tocopheryl Acetate (Vitamin E), Citral, D-Limonene, Linalool.   ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "SAÇ BAKIMI",
    url: "img/portfolio/125.jpg",
  },
  {
    id: 126,
    name: "Keratin Saç Bakım Maskesi 150gr				",
    description: "Hairnice Keratin Saç Bakım Maskesi, Keratin, Provitamin B5, Organik Argan Yağı, Hindistan Cevizi Yağı, Tatlı Badem Yağı ve Jojoba Yağı ile güçlendirilmiş içeriği ile saçları beslemeye, güçlendirmeye, dayanıklılık ve canlılık kazandırmaya yardımcı olur. Dermatolojik olarak test edilmiştir.      ",
    description2:"Hairnice Keratin Saç Maskesi, temizlenmiş ve durulanmış saça uygulanır. Avuç içine yeterli miktarda alınarak saç diplerinden saç uçlarına kadar dairesel hareketlerle uygulanır. 15-20 dakika bekletilir ve daha sonra bol suyla iyice durulanır. Haftada 2-3 defa uygulanabilir. ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "SAÇ BAKIMI",
    url: "img/portfolio/126.jpg",
  },
 
  {
    id: 127,
    name: "Shea Butter Şampuan 330ml			",
    description: "  Hairnice Shea Butter Özlü Şampuan, doğal shea butter (Karite yağı) içeren özel formülü sayesinde saç bakımında kullanılabilir.  Kuru, yıpranmış ve normal saçlar içindir.    ",
    description2:"Islak saçın tamamına nazikçe masaj yaparak uygulayın ve 2 dakika kadar bekletin. Daha sonra bol suyla durulayın. Gerekirse uygulamayı tekrarlayın. Düzenli olarak kullanılması tavsiye edilir.   ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "SAÇ BAKIMI",
    url: "img/portfolio/127.jpg",
  },
  {
    id: 128,
    name: "Argan Şampuan 330ml				",
    description: " Hairnice Argan Özlü Şampuan, organik argan yağı içeren özel formülü sayesinde saç bakımında kullanılabilir. Kuru, yıpranmış ve normal saçlar içindir.   ",
    description2:"Islak saçın tamamına nazikçe masaj yaparak uygulayın ve 2 dakika kadar bekletin. Daha sonra bol suyla durulayın. Gerekirse uygulamayı tekrarlayın. Düzenli olarak kullanılması tavsiye edilir.   ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "SAÇ BAKIMI",
    url: "img/portfolio/128.jpg",
  },
  {
    id: 129,
    name: "Aktif Karbon Şampuan 330ml			",
    description: " Hairnice Aktif Karbon Özlü Şampuan, doğal aktif karbon içeren özel formülü sayesinde saç bakımında kullanılabilir.  Yağlı, kepekli ve normal saçlar içindir. ",
    description2:" Islak saçın tamamına nazikçe masaj yaparak uygulayın ve 2 dakika kadar bekletin. Daha sonra bol suyla durulayın. Gerekirse uygulamayı tekrarlayın. Düzenli olarak kullanılması tavsiye edilir.       ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "SAÇ BAKIMI",
    url: "img/portfolio/129.jpg",
  },
 
  {
    id: 130,
    name: "Biotin Şampuan (Dökülme Karşıtı) 330ml				",
    description: "  Paraben, SLS ve tuz içermez.   ",
    description2:"Biodestek Biotin Şampuan, ginseng, zerdeçal ekstraktı ve keratinle güçlendirilmiş, bitkisel içerikli özel formülüyle, dökülen ve zayıflayan saçların bakımı için idealdir.   Paraben ve SLS içermez. Günlük kullanıma uygundur.       ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "SAÇ BAKIMI",
    url: "img/portfolio/130.jpg",
  },




        // DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR-------------
   // DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR-------------
      // DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR-------------
         // DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR-------------
            // DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR------------- DOĞAL SABUNLAR-------------
  {
    id: 131,
    name: "Çay Ağacı Sabunu 150g					",
    description: " Destek Çay Ağacı Sabunu, çay ağacı içeren doğal bir sabundur. Çay ağacı yağı, hint defnesi adlı bitkiden elde edilen bir bitkisel yağdır. Diğer adıyla Melaleuca Alternifolia Leaf, yassı yapraklı bir bitkidir. Bitki mersingiller cinsine aittir. Avustralya’ya özgü olan bu tür, uzun yıllardır yetiştirilmesine rağmen, son zamanlarda sıkça tüketilmeye başlanmıştır. Cilt bakımında yoğun olarak kullanılmaktadır. Çay ağacı sabunu, cildi derinlemesine temizler. Siyah noktaların giderilmesine yardımcı olur.   ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Melaleuca Alternifolia Leaf Oil, CI 19140, CI 42090, Pentasodium Pentetate, Tetrasodium Etidronate, Hexyl Cinnamal, Linalool, Limonene, Citronellol, Citral      ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/131.jpg",
  },
  {
    id: 132,
    name: "Kırmızı Yosun Sabunu 150g						",
    description: "  Destek Kırmızı Yosun Sabunu, kırmızı yosun içeren doğal bir sabundur. Kırmızı yosun (Kırmızı alg), latince ismiyle, Furcellaria lumbricalis, özellikle İskandinav sularında yetişen bir yosun türüdür ve son dönemlerde cilt bakımında kullanımı dünyada gitgide artmaktadır. Kırmızı yosun cildin nemli kalmasını sağlar ve düzenli kullanım ile kırışıklıkların önlenmesine yardımcı olur.   ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, CI 16255, Pentasodium Pentetate, Tetrasodium Etidronate, Phenethyl Alcohol, Sodium Carrageenan, Hydroxyisohexyl-3-Cyclohexene Carboxaldehyde, Butylphenyl Methylpropional, Linalool, Sea Salt, Limonene, Citral.      ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/132.jpg",
  },
 
  {
    id: 133,
    name: "Collagen Sabun 150g (Collagen İçeren Sabun)					",
    description: " Destek Kollajen Sabunu, kollajen içeren doğal bir sabundur.  Kollajen, kemik, kıkırdak ve lif gibi hareket sisteminin temel yapı taşlarını oluşturan bir tür proteindir. Son zamanlarda, özellikle cilt bakımında kullanımı, dünya çapında oldukça yaygınlaşmıştır. Kollajen sabunu, doğal içeriği ile tüm ciltler için uygundur..   ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, CI 16255, Pentasodium Pentetate, Tetrasodium Etidronate, Hydrolyzed Collagen, CI 42090, Phenoxyethanol.      ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/133.jpg",
  },
  {
    id: 134,
    name: "Aktif Karbon Sabunu 150g					",
    description: " Destek Aktif Karbon Sabunu, aktif karbon içeren doğal bir sabundur. Aktif karbon (Aktif kömür), hindistan cevizi veya bambu ağacından elde edilen organik bir maddedir. Son yıllarda dünya çapında popülerliği gitgide artan aktif karbon, özellikle cilt ve diş bakımı için tercih edilen doğal bileşenlerden biri haline gelmiştir. Aynı zamanda sabun yapımında da kullanılan bu madde, cilt bakımında birçok faydası ile bilinmektedir. Aktif karbon sabunu, cildinizin doğal yoldan temizlenmesine yardımcı olur.   ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Charcoal Powder, Pentasodium Pentetate, Tetrasodium Etidronate.      ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/134.jpg",
  },
 

  {
    id: 135,
    name: "Hindistan Cevizi Sabunu 150g					",
    description: "  Destek Hindistan Cevizi Sabunu, hindistan cevizi içeren doğal bir sabundur.  Hindistan Cevizi Yağı, latince ismiyle Cocos nucifera, hindistan cevizi çekirdeklerinden, soğuk pres yöntemiyle elde edilen besleyici ve değerli bir yağdır. Tropik bölgelerde yetişen hindistan cevizi, en çok tercih edilen doğal çözümlerden biridir. Özellikle son zamanlarda cilt ve saç bakımında kullanımı oldukça yaygınlaşmıştır. Hoş ve egzotik kokusu ile hindistan cevizi sabunu, ferahlık hissi yaratır. Cildinizi narince temizler. Saç bakımı için de kullanılabilir.    ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Cocos Nucifera Oil, Pentasodium Pentetate, Tetrasodium Etidronate, Coumarin, Limonene, Hexyl Cinnamal, Linalool, Citronellol.      ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/135.jpg",
  },
  {
    id: 136,
    name: "Turunçgil Bahçesi Sabunu 150g						",
    description: "  Destek Turunçgil Bahçesi Sabunu, turunçgiller içeren doğal bir sabundur. İçeriğinde turunçgiller familyasına ait meyvelerin yağlarını bulunduran bu sabun, tüm cilt tipleri için uygundur. Cilde ferahlık hissi verir ve cildin temizlenmesine yardımcı olur.   ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Citrus Limon Peel Oil, Citrus Sinensis Peel Oil Expressed, Citrus Grandis Seed Oil, Pentasodium Pentetate, Tetrasodium Etidronate, D-Limonene, Linalool, CI 16035, CI 19140      ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/136.jpg",
  },
 
  {
    id: 137,
    name: "Pirinç Kepeği Sabunu 150g						",
    description: " Destek Pirinç Kepeği Sabunu, pirinç kepeği yağı içeren doğal bir sabundur. Pirinç kepeği yağı, pirincin dış kabuğu ile çekirdeği arasındaki sert ve yağlı tabakadan elde edilen bir bitkisel yağdır. Bu kısma aynı zamanda kepek adı da verilir. Dünya genelinde sıkça tüketilen bu bitkisel yağın çoğunluğunu Asya kıtasındaki ülkeler almaktadır. Vücudun ihtiyaç duyduğu vitamin ve mineralleri içerisinde barındıran pirinç kepeği yağı, cilt ve saç bakımında kullanılabilmektedir. Pirinç Kepeği Sabunu, cildin beslenmesine yardımcı olur ve lekelerin giderilmesini sağlar.   ",
    description2:" Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Oryza Sativa (Rice) Bran Oil, Pentasodium Pentetate, Tetrasodium Etidronate.      ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/137.jpg",
  },
  {
    id: 138,
    name: "Avokado Sabunu 150g						",
    description: "  Destek Avokado Sabunu, avokado içeren doğal bir sabundur. Avokado, sıcak iklim bölgelerinde yetişen bir tropikal meyvedir. Ülkemizde çok yaygın olmamakla birlikte, Akdeniz bölgesinde yetişebilmektedir. Avokado, içeriğinde birçok minerali ve A, C vitaminlerini bulundurur. Avokado sabunu, cilt temizliğine yardımcı olur ve rahatlık hissi verir.   ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Persea Gratissima Oil, Pentasodium Pentetate, Tetrasodium Etidronate, CI 19140, CI 42090, Hydroxyisohexyl-3-Cyclohexene Carboxaldehyde, Butylphenyl Methylpropional, Linalool, Limonene, Citral      ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/138.jpg",
  },

  {
    id: 139,
    name: "Lavanta Sabunu 150g					",
    description: "  Destek Lavanta Sabunu, lavanta içeren doğal bir sabundur. Lavanta, yüksek ve kırsal kesimlerde yetişen, mor çiçekli ve hoş kokulu bir bitkidir. Ballıbabagiller familyasına ait bir türdür. Belirgin özelliklerinden olan kokusu ve rengi, lavantayı birçok alanda kullanılabilir kılar. Ferahlatıcı, rahatlatıcı ve hoş kokulu bir sabundur.  Cilde canlılık ve tazelik kazandırmaya yardımcı olur.   ",
    description2:" Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Lauric Acid, Sodium Chloride, Stearic Acid, Lavandula Angustifolia Oil, Pentasodium Pentetate, Tetrasodium Etidronate, Coumarin, Linalool, CI 16255, CI 42090.      ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/139.jpg",
  },
  {
    id: 140,
    name: "E Vitamini Sabunu 150g						",
    description: "  Destek E-Vitamini Sabunu, E-Vitamini içeren doğal bir sabundur. E-Vitamini yağda çözülebilen vitamin gruplarından bir tanesidir. Cilt için en önemli vitaminlerin başında gelen E-vitamini, güçlü bir antioksidandır. Bilinen diğer faydaları ise; yaraların çabuk iyileşmesini sağlaması, cilde parlak bir görünüm kazandırması, cildin nem dengesini desteklemesi ve saç sağlığına yardımcı olmasıdır. E-Vitamini Sabunu tüm cilt tipleri için uygundur.   ",
    description2:" Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Tocopheryl Acetate, Pentasodium Pentetate, Tetrasodium Etidronate, Benzyl Salicylate, Hydroxycitronellal, Butylphenyl Methylpropional, Citronellol, Linalool, Hexyl Cinnamal, Geraniol, Hydroxyisohexyl-3-Cyclohexene Carboxaldehyde, CI 19140      ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/140.jpg",
  },
 
  {
    id: 141,
    name: "Kayısı Sabunu 150g					",
    description: "  Destek Kayısı Sabunu,kayısı meyvesini içeren doğal bir sabundur. Besin değerleri oldukça yüksek bir meyve olan kayısı, içeriğinde A, C ve E vitaminlerini bulundurur. Günlük el ve yüz temizliğinde kullanılır. Cildin canlı ve parlak bir görünüm kazanmasına yardımcı olur. Saç bakımında da tercih edilmektedir.   ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Prunus Armeniaca Kernel Oil, Pentasodium Pentetate, Tetrasodium Etidronate, CI 19140, CI 16255      ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/141.jpg",
  },
  {
    id: 142,
    name: "Ardıç Katranı Sabunu 150g					",
    description: "  Destek Ardıç Katranı Sabunu, ardıç katranı içeren doğal bir sabundur. Ardıç ağacından çıkarılan katranın yağından üretilen bir sabundur. Cilt hastalıkları için kullanılabilmektedir. Lekelerin ve siyah noktaların giderilmesinde yardımcı olur.   ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Juniperus Communis Fruit Oil, Sodium Chloride, Stearic Acid, Lauric Acid, Pentasodium Pentetate, Tetrasodium Etidronate, CI 16035, CI 42090, CI 19140      ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/142.jpg",
  },
 

  {
    id: 143,
    name: "Çam Terebentin Sabunu 150g					",
    description: " Destek Çam Terebentin Sabunu, çam terebentin içeren doğal bir sabundur. Akne ve sivilcelere karşı yardımcı olur ve cildin sıkılaşmasını destekler. Tüm cilt tiplerine uygundur.   ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol,Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Pine Turpentine Oil, Sodium Chloride, Stearic Acid, Lauric Acid, Pentasodium Pentetate, Tetrasodium Etidronate, CI 42090, CI 19140   ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/143.jpg",
  },
  {
    id: 144,
    name: "Salyangoz Sabunu 150g						",
    description: "  Destek Salyangoz Sabunu, salyangoz salgı özü içeren doğal bir sabundur. Salyangozların kendi kendini yenileme özelliği salgılarındaki Allantoin maddesi sayesindedir. Salyangoz sabunu, içeriğindeki allantoin sayesinde, onarıcı bir etki sağlar ve cilt yaralarının giderilmesine yardımcı olur. Tüm cilt tipleri için uygundur.   ",
    description2:"Aqua, Glycerin, SodiumStearate, PropyleneGlycol, Sorbitol, SodiumLaurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Snail Extract, Pentasodium Pentetate, Tetrasodium Etidronate   ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/144.jpg",
  },
 
  {
    id: 145,
    name: "Aynısefa (Calendula) Sabunu 150g					",
    description: " Destek Aynısefa (Calendula) Sabunu, aynısefa içeren doğal bir sabundur. Aynısefa, Akdeniz bölgesine özgü bir bitkidir. Genellikle sarı veya turuncu renktedir ve aromatik bir bitkidir. Bilinen diğer adıyla Calendula bitkisi, tohumlarında yüksek miktarda calendic asit içeren yağları bulundurur. Aynısefa yağı içeren sabunlar, yüz ve vücut için kullanılabilir. Lekelerin giderilmesine yardımcı olur ve onarıcı bir etki sağlar. Tüm cilt tipleri için uygundur.   ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Helianthus Annuus Seed Oil, Calendula Officinalis Flower Extract, Pentasodium Pentetate, Tetrasodium Etidronate, CI 19140, CI 16035.   ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/145.jpg",
  },
  {
    id: 146,
    name: "Isparta Gülü Sabunu 150g					",
    description: "  Destek Isparta Gülü Sabunu, Isparta gülü içeren doğal bir sabundur. Gül, hoş kokusu ve dokusu sayesinde günlük yaşamın her alanında kullanılır. Kokusu sayesinde sabun, şampuan ve parfümlerin içeriğinde bulunabilmektedir. Türkiye, gül üretimi konusunda uluslarası alanda ileri gelen ülkelerden biridir. Ülkemizde Isparta’da yetiştirilen bu güzel bitki, Destek Isparta Gülü Sabunu’nun içeriğini oluşturur. Isparta gülü sabunu cildin temizlenmesini destekler ve kokusu ile güzel bir etki bırakır.   ",
    description2:"Aqua,Glycerin, Sodium Stearate, Propylene Glycol,Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Lauric Acid, Sodium Chloride, Stearic Acid, Rosa Damascena Flower Extract, Pentasodium Pentetate, Tetrasodium Etidronate,  CI 16255, Geraniol, Linalool, Citronellol, Eugenol   ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/146.jpg",
  },
 
 
  {
    id: 147,
    name: "Egzotic sabun 150g					",
    description: "  Destek Egzotik Sabun, egzotik meyve içeren doğal bir sabundur. İçeriğinde birçok bitkisel yağ bulunduran egzotik sabun, hoş kokusu ile ciltte etki bırakır ve temizlenmesine yardımcı olur. Uzak ve tropikal ülkelerden gelen değerli meyve kokularıyla, canlandırıcı etki sağlar.   ",
    description2:" Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Vanilla Planifolia Fruit Oil, Jasminum Officinale Oil, Cananga Odorata Leaf Oil, Santalium Album Oil, Lilium Candidum Flower Extract, Pentasodium Pentetate, Tetrasodium Etidronate, D-Limonene, Linalool, CI 19140, CI 16035   ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/147.jpg",
  },
  {
    id: 148,
    name: "Argan Sabunu 150g					",
    description: " Destek Argan Sabunu, argan içeren doğal bir sabundur. Argan, latince ismiyle, Argania spinosa, Fas'ta yetişen ve yağı, ağırlıklı olarak cilt ve saç bakımında kullanılan değerli bir bitkidir. Argan sabunu, içeriğinde bulunan bileşenler ile cildin yumuşamasına yardımcı olur. Cildin temizlenmesini destekler  ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Argania Spinosa Kernel Oil, Pentasodium Pentetate, Tetrasodium Etidronate, CI 16035, CI 19140, CI42090.   ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/148.jpg",
  },
 

  // {
  //   id: 149,
  //   name: "Kükürt Sabunu 150g					",
  //   description: "  Destek Kükürt Sabunu, kükürt içeren doğal bir sabundur. Kükürt, eski çağlardan bu yana bilinen ve doğada saf halde bulunabilen değerli bir elementtir. Sarı renklidir ve cilt bakımında kullanılabilir. Kükürt sabunu, akne ve sivilce oluşumunu engellemeye yardımcı olur. Cildin temizlenmesini destekler.    ",
  //   description2:"qua, Sulfur, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Sodium Chloride, Stearic Acid, Lauric Acid, Pentasodium Pentetate, Tetrasodium Etidronate.   ",
  //   description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
  //   number: "3",
  //   category: "DOĞAL SABUNLAR",
  //   url: "img/portfolio/131.jpg",
  // },
  {
    id: 149,
    name: "Shea Butter Sabunu 150g						",
    description: "  Destek Shea Butter Sabunu, shea butter içeren doğal bir sabundur. Shea yağı, shea ağacından elde edilmektedir. Bu ağaç, Afrika’da yetişen tropik bir ağaçtır. Uzun ömürlü olan bu ağaç, 15-20 yıl içinde meyve verebilmektedir. Shea yağı, searik asit ve oleik asitten gelen trigliserit yapıdadır. İçeriğinde A, D, E ve F vitaminlerini barındırır. Koku bakımından hindistan cevizi ile benzerlik gösterir. Bilinen diğer adıyla karite yağı, sabun yapımında kullanılmaktadır. Shea butter sabunu, yumuşak dokusuyla el ve yüz bakımında kullanışlıdır. Cildin nemlendirilmesine yardımcı olur ve rahatlatıcı bir etki sağlar.  ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Butyrospermum Parkii Butter, Pentasodium Pentetate, Tetrasodium Etidronate.   ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/131.jpg",
  },
 
  {
    id: 150,
    name: "Aloe Vera Sabunu 150g						",
    description: "  Destek Aloe Vera Sabunu, aloe vera içeren doğal bir sabundur. Aloe vera bitkisi, sıcak ve kuru iklimlerde yetişen bir bitkidir ve pek çok türü bulunmaktadır. Genellikle Afrika kıtasında bulunur ve zambakgiller familyasından olup kaktüse benzer bir görünümdedir. İçeriği “Aloe Barbadensis Extract” olarak tanımlanır ve içeriğindeki bileşen ve besin maddelerinin ekstrakt edilmesi, ardından taşıyıcı bir yağda bekletilmesiyle elde edilir. Aloe vera sabunu, cildin nemlendirilmesine destek olur ve kepek probleminin giderilmesine yardımcı olur. Aynı zamanda makyaj temizliğinde de kullanılabilmektedir.   ",
    description2:"Aqua, Glycerin, Sodium Stearate, Propylene Glycol, Sorbitol, Sodium Laurate, Sodium Laureth Sulfate, Sodium Lauryl Sulfate, Parfum, Sodium Chloride, Stearic Acid, Lauric Acid, Aloe Barbadensis Leaf, Pentasodium Pentetate, Tetrasodium Etidronate, CI 42090, CI 19140, Hydroxyisohexyl-3-Cyclohexene Carboxaldehyde, Butylphenyl Methylpropional, Linalool, Limonene, Citral  ",
    description3:"Kapağı kapalı olaraK tutunuz. ",
    
    
    number: "3",
    category: "DOĞAL SABUNLAR",
    url: "img/portfolio/150.jpg",
  },

  
  
  {
    id: 151,
    name: "ÇÖP TORBASI					",
    description: "      ",
    description2:"Müşterilerimizin istek talepleri doğrultusunda, her ebat, kalınlık ve renkte üretimi gerçekleştirilmektedir. ",
    description3:"Çevre Dostudur. ",
    
    
    number: "3",
    category: "ENDÜSTRİYEL KAĞIT ÜRÜNLER",
    url: "img/portfolio/151.jpg",
  },
 
  {
    id: 165,
    name: "Kutu Mendil						",
    description: "          * Tuvalet Kağıtları *Kağıt Havlular *Katlamalı Havlular *Peçeteler  *Kutu *Mendiller ",
    description2:"Müşterilerinin isteklerini karşılamak amacıyla her türlü temizlik kağıdı yarı ürününü üretebilmektedir.  ",
    description3:"Çevre Dostudur. ",
    
    
    number: "3",
    category: "ENDÜSTRİYEL KAĞIT ÜRÜNLER",
    url: "img/portfolio/165.jpg",
  },
  {
    id: 190,
    name: " Dispenser Havllu					",
    description: "          * Tuvalet Kağıtları *Kağıt Havlular *Katlamalı Havlular *Peçeteler  *Kutu *Mendiller ",
    description2:"Müşterilerinin isteklerini karşılamak amacıyla her türlü temizlik kağıdı yarı ürününü üretebilmektedir.  ",
    description3:"Çevre Dostudur. ",
    
    
    number: "3",
    category: "ENDÜSTRİYEL KAĞIT ÜRÜNLER",
    url: "img/portfolio/191.jpg",
  },
  {
    id: 191,
    name: "Kağıt Havlu						",
    description: "          * Tuvalet Kağıtları *Kağıt Havlular *Katlamalı Havlular *Peçeteler  *Kutu *Mendiller ",
    description2:"Müşterilerinin isteklerini karşılamak amacıyla her türlü temizlik kağıdı yarı ürününü üretebilmektedir.  ",
    description3:"Çevre Dostudur. ",
    
    
    number: "3",
    category: "ENDÜSTRİYEL KAĞIT ÜRÜNLER",
    url: "img/portfolio/192.jpg",
  },
  {
    id: 192,
    name: "Kağıt Havlu						",
    description: "          * Tuvalet Kağıtları *Kağıt Havlular *Katlamalı Havlular *Peçeteler  *Kutu *Mendiller ",
    description2:"Müşterilerinin isteklerini karşılamak amacıyla her türlü temizlik kağıdı yarı ürününü üretebilmektedir.  ",
    description3:"Çevre Dostudur. ",
    
    
    number: "3",
    category: "ENDÜSTRİYEL KAĞIT ÜRÜNLER",
    url: "img/portfolio/193.jpg",
  },
    {
    id: 193,
    name: "Kağıt 						",
    description: "          * Tuvalet Kağıtları *Kağıt Havlular *Katlamalı Havlular *Peçeteler  *Kutu *Mendiller ",
    description2:"Müşterilerinin isteklerini karşılamak amacıyla her türlü temizlik kağıdı yarı ürününü üretebilmektedir.  ",
    description3:"Çevre Dostudur. ",
    
    
    number: "3",
    category: "ENDÜSTRİYEL KAĞIT ÜRÜNLER",
    url: "img/portfolio/194.jpg",
  },
  
  {
    id: 194,
    name: "Dispenser  						",
    description: "          * Tuvalet Kağıtları *Kağıt Havlular *Katlamalı Havlular *Peçeteler  *Kutu *Mendiller ",
    description2:"Müşterilerinin isteklerini karşılamak amacıyla her türlü temizlik kağıdı yarı ürününü üretebilmektedir.  ",
    description3:"Çevre Dostudur. ",
    
    
    number: "3",
    category: "ENDÜSTRİYEL KAĞIT ÜRÜNLER",
    url: "img/portfolio/202.jpg",
  },
  
  {
    id: 202,
    name: "Tuvalet Kağıtları						",
    description: "          * Tuvalet Kağıtları *Kağıt Havlular *Katlamalı Havlular *Peçeteler  *Kutu *Mendiller ",
    description2:"Müşterilerinin isteklerini karşılamak amacıyla her türlü temizlik kağıdı yarı ürününü üretebilmektedir.  ",
    description3:"Çevre Dostudur. ",
    
    
    number: "3",
    category: "ENDÜSTRİYEL KAĞIT ÜRÜNLER",
    url: "img/portfolio/195.jpg",
  },
  


  
  {
    id: 157,
    name: "PVC MERMER PANEL			",
    description: "   PVC mermer duvar panelleri iç mekan duvar kaplamalarında mermer ve seramiğin kullanıldığı tüm alanlarda alternatif olarak geliştirilmiştir. Paneller montaja hazır olup uygulaması temiz, kolay, kırıp dökmeye ihtiyaç duymadan müşterinin montajını yapabileceği şekilde kendin yap sistemli tasarlanıp üretilmiştir. *Çizilme ve çarpmaya karşı yüksek yüzey direnci *Orijinal mermer sertliği *Antibakteriyel,formaldehitsiz,%100 asbestsiz *B1 sınıfı alev yürütmez, toksik duman salgılamaz *Su geçirmez ve nem dayanımı yüksek *Hızlı ve temiz kurulum, geniş model seçeneği *UV katmanlı parlak yüzey Temizliği ve bakımı kolay *Radyasyonsuz, çevre dostu *Isı ve ses yalıtımı,5 yıl garanti ",
 
    
    
    number: "3",
    category: "PVC MERMER PANEL",
    url: "img/portfolio/157.jpg",
  },
  
  {
    id: 159,
    name: "PVC MERMER PANEL			",
    description: "   PVC mermer duvar panelleri iç mekan duvar kaplamalarında mermer ve seramiğin kullanıldığı tüm alanlarda alternatif olarak geliştirilmiştir. Paneller montaja hazır olup uygulaması temiz, kolay, kırıp dökmeye ihtiyaç duymadan müşterinin montajını yapabileceği şekilde kendin yap sistemli tasarlanıp üretilmiştir. *Çizilme ve çarpmaya karşı yüksek yüzey direnci *Orijinal mermer sertliği *Antibakteriyel,formaldehitsiz,%100 asbestsiz *B1 sınıfı alev yürütmez, toksik duman salgılamaz *Su geçirmez ve nem dayanımı yüksek *Hızlı ve temiz kurulum, geniş model seçeneği *UV katmanlı parlak yüzey Temizliği ve bakımı kolay *Radyasyonsuz, çevre dostu *Isı ve ses yalıtımı,5 yıl garanti ",
 
    
    
    number: "3",
    category: "PVC MERMER PANEL",
    url: "img/portfolio/159.jpg",
  },{
    id: 160,
    name: "PVC MERMER PANEL			",
    description: "   PVC mermer duvar panelleri iç mekan duvar kaplamalarında mermer ve seramiğin kullanıldığı tüm alanlarda alternatif olarak geliştirilmiştir. Paneller montaja hazır olup uygulaması temiz, kolay, kırıp dökmeye ihtiyaç duymadan müşterinin montajını yapabileceği şekilde kendin yap sistemli tasarlanıp üretilmiştir. *Çizilme ve çarpmaya karşı yüksek yüzey direnci *Orijinal mermer sertliği *Antibakteriyel,formaldehitsiz,%100 asbestsiz *B1 sınıfı alev yürütmez, toksik duman salgılamaz *Su geçirmez ve nem dayanımı yüksek *Hızlı ve temiz kurulum, geniş model seçeneği *UV katmanlı parlak yüzey Temizliği ve bakımı kolay *Radyasyonsuz, çevre dostu *Isı ve ses yalıtımı,5 yıl garanti ",
 
    
    
    number: "3",
    category: "PVC MERMER PANEL",
    url: "img/portfolio/160.jpg",
  },{
    id: 161,
    name: "PVC MERMER PANEL			",
    description: "   PVC mermer duvar panelleri iç mekan duvar kaplamalarında mermer ve seramiğin kullanıldığı tüm alanlarda alternatif olarak geliştirilmiştir. Paneller montaja hazır olup uygulaması temiz, kolay, kırıp dökmeye ihtiyaç duymadan müşterinin montajını yapabileceği şekilde kendin yap sistemli tasarlanıp üretilmiştir. *Çizilme ve çarpmaya karşı yüksek yüzey direnci *Orijinal mermer sertliği *Antibakteriyel,formaldehitsiz,%100 asbestsiz *B1 sınıfı alev yürütmez, toksik duman salgılamaz *Su geçirmez ve nem dayanımı yüksek *Hızlı ve temiz kurulum, geniş model seçeneği *UV katmanlı parlak yüzey Temizliği ve bakımı kolay *Radyasyonsuz, çevre dostu *Isı ve ses yalıtımı,5 yıl garanti ",
 
    
    
    number: "3",
    category: "PVC MERMER PANEL",
    url: "img/portfolio/161.jpg",
  },
  {
    id: 163,
    name: "PVC MERMER PANEL			",
    description: "   PVC mermer duvar panelleri iç mekan duvar kaplamalarında mermer ve seramiğin kullanıldığı tüm alanlarda alternatif olarak geliştirilmiştir. Paneller montaja hazır olup uygulaması temiz, kolay, kırıp dökmeye ihtiyaç duymadan müşterinin montajını yapabileceği şekilde kendin yap sistemli tasarlanıp üretilmiştir. *Çizilme ve çarpmaya karşı yüksek yüzey direnci *Orijinal mermer sertliği *Antibakteriyel,formaldehitsiz,%100 asbestsiz *B1 sınıfı alev yürütmez, toksik duman salgılamaz *Su geçirmez ve nem dayanımı yüksek *Hızlı ve temiz kurulum, geniş model seçeneği *UV katmanlı parlak yüzey Temizliği ve bakımı kolay *Radyasyonsuz, çevre dostu *Isı ve ses yalıtımı,5 yıl garanti ",
 
    
    
    number: "3",
    category: "PVC MERMER PANEL",
    url: "img/portfolio/163.jpg",
  },
  
  
  
  
  {
    id: 167,
    name: "Klasik Mutfak		",
 
    
    
    number: "3",
    category: "Mutfak Tasarımı",
    url: "img/portfolio/167.jpg",
  },
 
  {
    id: 166,
    name: "Klasik Mutfak			",
 
    
    
    number: "3",
    category: "Mutfak Tasarımı",
    url: "img/portfolio/166.jpg",
  },

  
  {
    id: 154,
    name: " Modern Mutfak  			",
 
    
    
    number: "3",
    category: "Mutfak Tasarımı",
    url: "img/portfolio/154.jpg",
  },
  {
    id: 155,
    name: "  Modern Mutfak   			",
 
    
    
    number: "3",
    category: "Mutfak Tasarımı",
    url: "img/portfolio/155.jpg",
  },
  {
    id: 153,
    name: "   Retro         			",
 
    
    
    number: "3",
    category: "Mutfak Tasarımı",
    url: "img/portfolio/153.jpg",
  },
  {
    id: 152,
    name: "    Retro         			",
 
    
    
    number: "3",
    category: "Mutfak Tasarımı",
    url: "img/portfolio/152.jpg",
  },

  {
    id: 168,
    name: "   Klasik Kapı           			",
 
    
    
    number: "3",
    category: "Kapı Tasarımı",
    url: "img/portfolio/168.jpg",
  },
  {
    id: 169,
    name: "   Klasik Kapı           			",
 
    
    
    number: "3",
    category: "Kapı Tasarımı",
    url: "img/portfolio/169.jpg",
  },
 
  {
    id: 170,
    name: "           Modern Kapı                                                         			",
 
    
    
    number: "3",
    category: "Kapı Tasarımı",
    url: "img/portfolio/170.jpg",
  },
  {
    id: 171,
    name: "           Modern Kapı                                                         			",
 
    
    
    number: "3",
    category: "Kapı Tasarımı",
    url: "img/portfolio/171.jpg",
  },
  {
    id: 172,
    name: "               Sürgü Kapı                                                          			",
 
    
    
    number: "3",
    category: "Kapı Tasarımı",
    url: "img/portfolio/172.jpg",
  },
  {
    id: 173,
    name: "               Sürgü Kapı                                                    			",
 
    
    
    number: "3",
    category: "Kapı Tasarımı",
    url: "img/portfolio/173.jpg",
  },
  {
    id: 174,
    name: "               Kapı Çözümleri                                                                                                            			",
 
    
    
    number: "3",
    category: "Kapı Tasarımı",
    url: "img/portfolio/174.jpg",
  },
  {
    id: 196,
    name: "               Kapı Çözümleri                                                                                                            			",
 
    
    
    number: "3",
    category: "Kapı Tasarımı",
    url: "img/portfolio/196.jpg",
  },

  {
    id: 197,
    name: "               Kapı Çözümleri                                                                                                            			",
 
    
    
    number: "3",
    category: "Kapı Tasarımı",
    url: "img/portfolio/197.jpg",
  },
  {
    id: 198,
    name: "               Kapı Çözümleri                                                                                                            			",
 
    
    
    number: "3",
    category: "Kapı Tasarımı",
    url: "img/portfolio/198.jpg",
  },
  {
    id: 199,
    name: "               Kapı Çözümleri                                                                                                            			",
 
    
    
    number: "3",
    category: "Kapı Tasarımı",
    url: "img/portfolio/199.jpg",
  },
  {
    id: 200,
    name: "               Kapı Çözümleri                                                                                                            			",
 
    
    
    number: "3",
    category: "Kapı Tasarımı",
    url: "img/portfolio/200.jpg",
  },

  {
    id: 175,
    name: "              Klasik Banyo                                                                                                          			",
 
    
    
    number: "3",
    category: "Banyo Tasarımı",
    url: "img/portfolio/175.jpg",
  },
  {
    id: 176,
    name: "          Klasik Banyo                                                                                                   			",
 
    
    
    number: "3",
    category: "Banyo Tasarımı",
    url: "img/portfolio/176.jpg",
  },

  {
    id: 177,
    name: "               Modern Banyo                                                                                                           			",
 
    
    
    number: "3",
    category: "Banyo Tasarımı",
    url: "img/portfolio/177.jpg",
  },

  {
    id: 178,
    name: "               Modern Banyo                                                                                                              			",
 
    
    
    number: "3",
    category: "Banyo Tasarımı",
    url: "img/portfolio/178.jpg",
  },

  {
    id: 179,
    name: "              TV Üniteleri                                                                                                                    			",
 
    
    
    number: "3",
    category: "İç Mekan Tasarımı",
    url: "img/portfolio/179.jpg",
  },
  {
    id: 180,
    name: "              Yatak Odası                                                                                                                  			",
 
    
    
    number: "3",
    category: "İç Mekan Tasarımı",
    url: "img/portfolio/180.jpg",
  },
  
  {
    id: 181,
    name: "             Giyinme Odası                                                                                                                                                                             			",
 
    
    
    number: "3",
    category: "İç Mekan Tasarımı",
    url: "img/portfolio/181.jpg",
  },
  // {
  //   id: 182,
  //   name: "              Giyinme Odası                                                                                                                                                                     			",
 
    
    
  //   number: "3",
  //   category: "İç Mekan Tasarımı",
  //   url: "img/portfolio/182.jpg",
  // },
  {
    id: 183,
    name: "            Otel Odası Dizayn ve Uygulaması                                                                                                                                                                       			",
 
    
    
    number: "3",
    category: "İç Mekan Tasarımı",
    url: "img/portfolio/183.jpg",
  },

  {
    id: 184,
    name: "            Otel Odası Dizayn ve Uygulaması                                                                                                                                                                       			",
 
    
    
    number: "3",
    category: "İç Mekan Tasarımı",
    url: "img/portfolio/184.jpg",
  },

  {
    id: 186,
    name: "          Ofis Tasarım ve Uygulamaları                                                                                                                                                                             			",
 
    
    
    number: "3",
    category: "İç Mekan Tasarımı",
    url: "img/portfolio/186.jpg",
  },

  {
    id: 187,
    name: "             Otel Tasarım ve Uygulamaları                                                                                                                                                                                                			",
 
    
    
    number: "3",
    category: "İç Mekan Tasarımı",
    url: "img/portfolio/187.jpg",
  },
  {
    id: 188,
    name: "         Otel Tasarım ve Uygulamaları                                                                                                                                                                                                               			",
 
    
    
    number: "3",
    category: "İç Mekan Tasarımı",
    url: "img/portfolio/188.jpg",
  },
  {
    id: 201,
    name: "         Otel Tasarım ve Uygulamaları                                                                                                                                                                                                               			",
 
    
    
    number: "3",
    category: "İç Mekan Tasarımı",
    url: "img/portfolio/201.jpg",
  },
  {
    id: 189,
    name: "                        Dış Mekan Tasarım ve Uygulamaları                                                                                                                                                                                                                         			",
 
    
    
    number: "3",
    category: "Dış Mekan Tasarımı",
    url: "img/portfolio/189.jpg",
  },





  {
    id: 203,
    name: "                      Duş Kabinleri                                                                                                                                                                                                                           			",
 
    
    
    number: "3",
    category: "BANYO",
    url: "img/portfolio/203.jpg",
  },

  {
    id: 204,
    name: "                     Kompakt Sistemler                                                                                                                                                                                                                         			",
 
    
    
    number: "3",
    category: "BANYO",
    url: "img/portfolio/204.jpg",
  },
 
  {
    id: 205,
    name: "                    Özel Küvetler                                                                                                                                                                                                                         			",
 
    
    
    number: "3",
    category: "BANYO",
    url: "img/portfolio/205.jpg",
  },
 
  {
    id: 206,
    name: "                    Klasik Küvetler                                                                                                                                                                                                                               			",
 
    
    
    number: "3",
    category: "BANYO",
    url: "img/portfolio/206.jpg",
  },
 
  {
    id: 207,
    name: "                     Duş Tekneleri                                                                                                                                                                                                                                                                                          			",
 
    
    
    number: "3",
    category: "BANYO",
    url: "img/portfolio/207.jpg",
  },
 
  {
    id: 208,
    name: "                      Banyo Aksesuarları                                                                                                                                                                                                                                			",
 
    
    
    number: "3",
    category: "BANYO",
    url: "img/portfolio/208.jpg",
  },
 
  {
    id: 209,
    name: "                  Banyo Aksesuarları                                                                                                                                                                                                                               			",
 
    
    
    number: "3",
    category: "BANYO",
    url: "img/portfolio/209.jpg",
  },
 
 


];
const categorylist = [
  { id: 1, category: "Tümü", value: "Tümü" },
  { id: 2, category: "100% SAF BİTKİ YAĞLARI", value: "100% SAF BİTKİ YAĞLARI" },
  { id: 3, category: "ÖZEL KARIŞIM YAĞLAR", value: "ÖZEL KARIŞIM YAĞLAR" },
  { id: 4, category: "CİLT BAKIMI", value: "CİLT BAKIMI" },
  { id: 5, category: "SAÇ BAKIMI", value: "SAÇ BAKIMI" },
  { id: 6, category: "DOĞAL SABUNLAR", value: "DOĞAL SABUNLAR" },
  { id: 6, category: "ENDÜSTRİYEL KAĞIT ÜRÜNLER", value: "ENDÜSTRİYEL KAĞIT ÜRÜNLER" },
  { id: 7, category: "PVC MERMER PANEL", value: "PVC MERMER PANEL" },
  { id: 8, category: "Mutfak Tasarımı", value: "Mutfak Tasarımı" },
  { id: 9, category: "Kapı Tasarımı", value: "Kapı Tasarımı" },
  { id: 10, category: "Banyo Tasarımı", value: "Banyo Tasarımı" },
  { id: 11, category: "İç Mekan Tasarımı", value: "İç Mekan Tasarımı" },

  { id: 12, category: "Dış Mekan Tasarımı", value: "Dış Mekan Tasarımı" },
  { id: 13, category: "BANYO", value: "BANYO" },

  // { id: 12, category: "Lake ve PVC Boyama Dolap Kapağı", value: "Lake ve PVC Boyama Dolap Kapağı" },
  
];



function Portfolio() {
  const [filter, setFilter] = useState("Tümü");

  const filteredPortfolio = useMemo(() => {
    if (filter === "Tümü") return portfoliolist;

    return portfoliolist.filter((portfolio) => portfolio.category === filter);
  }, [filter]);

  return (
    <div className="text-center" id="portfolio">
      <Container>
        <Row>
          <Col md="12">
            <ul  className="filter-name little-margin nav navbar-nav ">
              {categorylist.map((types) => (
                <li className="filter-name btn-info active">
                  <a  className="filter-name  color " onClick={() => setFilter(types.category)}>
                    {types.category}
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
             
        <Row>
          {filteredPortfolio.map((item) => (
            <Col active md="4" className="mb-4 " key={item.id}>
              <PortfolioItem className="little-margin hover-bg hover-text" item={item} />
              
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
}

export default Portfolio;
