import React, { Component } from "react";
import { Router, Switch, Route } from "react-router-dom"
import Products from "./Products";
import EnProducts from "./EnProducts";

import history from './History';
import App from "./App";
import Iletisim from "./Iletisim";

import EnIletisim from "./EnIletisim";

import English from "./English";

export default class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <Switch>
                    <Route path="/" exact component={App} />
                    <Route path="/English" component={English} />

                    <Route path="/EnglishContact" component={EnIletisim} />
                    <Route path="/EnglishProducts" component={EnProducts} />


                    <Route path="/Contact" component={Iletisim} />
                    <Route path="/Products" component={Products} />
                </Switch>
            </Router>
        )
    }
}