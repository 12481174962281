import React from "react";
function PortfolioItem(props) {
  const { item } = props;
  return (
    <div className="portfolio-item galery-buttons   ">
    <div className="hover-bg ">
        <h5 className="h5bg ">{item.name}</h5>
        <div className="hover-text padding-top0"> 
        
         <div class="card" >
        <div class="card-body">
  {/* <h5 class="card-title">{item.name}</h5> */}
         <h5 class="card-subtitle mb-2 text-muted text-white"><b></b></h5>
  <p class="card-text p-product"><b></b>{item.description}</p>
  <p class="card-text p-product"><b></b>{item.description2}</p>
  <p class="card-text p-product"><b></b>{item.description3}</p>



  </div>
</div>
         
         </div>
        <div className="portfolio-item portfolio-images" > 
        <img className=" galery-img-size " src={item.url} alt= "deneme" ></img>
      
       </div>
      </div>
    </div>
  );
}
export default PortfolioItem;
