import React, { Component } from 'react'

export class kurumsal extends Component {
  render() {
    return (
        <div id="kurumsal">
        <div className="container">
          <div className="row">
          <div className="col-xs-12 col-md-6">
              <div className="kurumsal-text">
                <h2>Kurumsal</h2>
                <h3>Sloganımız...</h3>
                <p>{this.props.data ? this.props.data.paragraph : 'yükleniyor...'}</p>
                <h3>Vizyonumuz</h3>
                <div className="list-style">
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                      {this.props.data ? this.props.data.Why.map((d, i) => <li  key={`${d}-${i}`}>{d}</li>) : 'yükleniyor...'}
                    </ul>
                  </div>
                  <h3>Misyonumuz</h3>
                  <div className="col-lg-6 col-sm-6 col-xs-12">
                    <ul>
                    {this.props.data ? this.props.data.Why2.map((d, i) => <li  key={`${d}-${i}`}> {d}</li>) : 'yükleniyor...'}

                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-md-6"> <img src="img/intro-bg.jpg" className="img-responsive" alt=""/> </div>
            
          </div>
        </div>
      </div>
    )
  }
}

export default kurumsal
