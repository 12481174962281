import React, { Component } from "react";

export class OguzSancaktar extends Component {
  render() {
    return (
      <div className="padding__bottom__0" id="contact">
        <div id="footer">
          <div className="container text-center">
            <p>
              &copy; 2020-Agust Created by{" "}
              <a
                className="user__colorfull"
                href="http://www.instagram.com/oguzzsancaktar"
                target="blank"
                rel="nofollow"
              >
                <b>
                  <i>Oğuz Taha Sancaktar</i>
                </b>
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default OguzSancaktar;
